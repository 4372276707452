import React, { useEffect, useState } from 'react'
import Checkbox from '../../../Inputs/Checkbox'
import FormButton from '../../../Components/FormButton'
import '../../../Inputs.css'
import '../../../Radio.css'
import SimpleRadio from '../../../Components/Radio/SimpleRadio'
import { toast } from 'react-toastify';
import SimpleRadioWithOther from '../../../Components/Radio/SimpleRadioWithOther/simpleRadioWithOther'
import SimpleCheckboxWithOther from '../../../Components/Checkbox/SimpleChcekBoxWithOther/simpleCheckboxWithOther'
import FormSubmitted from './../../../assets/submitted.png'
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import Upload from '../../../Components/Upload/upload'
import Input from '../../../Inputs/Input'
import GroupedRadio from '../../../Inputs/GroupedRadio'
import SingleLineRadio from '../../../Inputs/SingleLineRadio'
import DOB from '../../../Inputs/Dob'
import moment from 'moment/moment'
import { GetCRMFeatures, GetCRMMedia, NyserdaPreTrainingFormApplications } from '../../../Apis/nyserdaPreSurvey'
import Dropdown from '../../../Inputs/Dropdown'
import CheckboxWithExpander from '../../../Components/Checkbox/CheckboxHavingChildrens'
import { Translate } from 'react-auto-translate'
import ReactGA from 'react-ga4'
import getIp from 'react-public-ip';
import { UAParser } from 'ua-parser-js'
import { Document, Page, pdfjs } from 'react-pdf'
import { NUMBER_REGEX } from '../../../utils/constants'


const PretrainingV3 = () => {

  const [loader, setLoader] = useState(false)
  const [btnDisabled, setBtnDisabled] = useState(true)
  const [coursesList, setCoursesList] = useState([])
  const [show, setShow] = useState(false)
  const [show1, setShow1] = useState(false)
  const [modalName, setModalName] = useState('')
  const [modalName1, setModalName1] = useState('')
  const [userExistsData, setUserExistsData] = useState({})
  const [agreeWithAgreement, setAgreeWithAgreement] = useState(false)
  const [mediaFeature, setMediaFeature] = useState('Yes')
  const [agreementRead1, setAgreementRead1] = useState(false)
  const [agreementRead, setAgreementRead] = useState(false)
  const [numPages, setNumPages] = useState(null)
  pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`

  const onDocumentLoadSuccess = ({ numPages }) => {
    console.log("numPages: ", numPages);
    setNumPages(numPages)
  }

  // User System Info
  const [userSystemInfo, setUserSystemInfo] = useState({info: "", ip: ""})

  // let dName = coursesList[0]?.fullname
  useEffect(() => {
      // setFormData({...formData, course: dName})
      document.title = "NYSERDA Pre Survey Form"
      ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: window.location.pathname });
      
      async function getIPAddress() {
        const ipv4 = await getIp.v4() || "";
        const parser = new UAParser();
        // console.log("ipv4: ", ipv4);
        setUserSystemInfo({...userSystemInfo, info: parser.getResult(), ip: ipv4})
      }
      getIPAddress()
      GetAgreementDetails()
  }, [])

  const [checked, setChecked] = useState(false)
  const [checked1, setChecked1] = useState(false)
  const [fileDate, setFileDate] = useState(null)
  const [fileDate1, setFileDate1] = useState(null)
  const [argreementUrl, setArgreementUrl] = useState('')

  const GetAgreementDetails = () => {
    try {
        GetCRMFeatures().then(res => {
          if (res !== null) {
            if (res?.data?.attributes?.status) {
                setChecked((res?.data?.attributes?.status))
                if (res?.data?.attributes?.feature_file?.data?.attributes) {
                  setFileDate(res?.data?.attributes?.feature_file?.data?.attributes)
                } else {
                  setFileDate(null)
                }
            } else {
              setChecked(false)
              setFileDate(null)
            }
          } else {
            setChecked(false)
            setFileDate(null)
          }
        })

        GetCRMMedia().then(res => {
          if (res !== null) {
            if (res?.data?.attributes?.status) {
                setChecked1((res?.data?.attributes?.status))
                if (res?.data?.attributes?.feature_file?.data?.attributes) {
                  setFileDate1(res?.data?.attributes?.feature_file?.data?.attributes)
                } else {
                  setFileDate1(null)
                }
            } else {
              setChecked1(false)
              setFileDate1(null)
            }
          } else {
            setChecked1(false)
            setFileDate1(null)
          }
        })
    } catch (error) {
      console.log("error: ", error, fileDate)
      setChecked(false)
      setChecked1(false)
      setFileDate(null)
      setFileDate1(null)
    }

    const convertToViewLink = (downloadLink) => {
      // Replace "download" with "view" in the URL
      const viewLink = downloadLink.replace('/download/', '/view/');
      return viewLink;
    };
    
    // Usage
    const downloadLink = 'https://unec.edu.az/application/uploads/2014/12/pdf-sample.pdf'
    const viewLink = convertToViewLink(downloadLink);
    setArgreementUrl(viewLink)
    console.log(viewLink); // Output: 'https
  }

  const NYSERDA_priorityPopulationsDynamic = {
    'Veterans': 'Veterans',
    'Native Americans': 'Native Americans',
    'Individuals with disabilities': 'Disabled',
    'Immigrant': 'Immigrant',
    'Refugee': 'Refugee',
    'English as a second language (ESL)': 'ESL',
    '1st Generation College': '1st Generation College',
    '2nd Generation College': '2nd Generation College',
    'Low income individuals - (60% of NYS median income or receiving public assistance)': 'Low Income',
    'Unemployed plant workers': 'Unemployed plant workers',
    'Previously incarcerated Individuals': 'Previously incarcerated',
    'Homeless Individuals': 'Homeless',
    '16 to 24 year olds in work preparedness training programs that include energy related technical Training': '16-24 years olds in WFD program',
    'Single parents': 'Single parents',
    'None of the above': 'None of the above'
  }

  const ethnicitiesDynamic = {
    'American Indian or Alaska Native (Eg: Navajo nation, Blackfeet tribe, Mayan, Aztec, Native Village or Barrow Inupiat Traditional Government, Nome Eskimo Community, etc)': 'American Indian or Alaska Native',
    'Asian (Eg: Chinese, Filipino, Asian Indian, Vietnamese, Korean, Japanese, etc)': 'Asian',
    'Black or African American (Eg: African American, Jamaican, Haitian, Nigerian, Ethiopian, Somalian, etc)': 'Black or African American',
    'Hispanic, Latino or Spanish (Eg: Mexican or Mexican American, Puerto Rican, Cuban, Salvadoran, Dominican, Colombian, etc)': 'Hispanic, Latino or Spanish',
    'Middle Eastern or North African (Eg: Lebanese, Iranian, Egyptian, Syrian, Moroccan, Algerian, etc)': 'Middle Eastern or North African',
    'Native Hawaiian or Other Pacific Islander (Eg: Native Hawaiian, Samoan, Chamorro, Tongan, Fijian, etc)': 'Native Hawaiian or Other Pacific Islander',
    'White (Eg: German, Irish, English, Italian, Polish, French, etc)': 'White',
    'I prefer not to say': "I prefer not to say"
  }

  const [formData, setFormData] = useState(
    {
        email: {value: '', dataSet: {question: "Email", isRequired: true, formDataKey: 'email', isError: false, errorMessage: ''}, type: 'simpleInput', inputType: 'text', isEmail: true, userVerified: false,placeholder:'Email'}, 
         
        fName: {value: '', dataSet: {question: "First Name", isRequired: true, formDataKey: 'fName', isError: false, errorMessage: ''}, type: 'simpleInput', inputType: 'text', disable: true,placeholder:'First Name'}, 

        lName: {value: '', dataSet: {question: "Last Name", isRequired: true, formDataKey: 'lName', isError: false, errorMessage: ''}, type: 'simpleInput', inputType: 'text', disable: true,placeholder:'Last Name'},

        // dateOfBirth: { value:'', dataSet: {question:"Date of Birth", isRequired: true, formDataKey: 'dateOfBirth', isError: false, errorMessage: ''}, type:'dob', disable:true},

        cellPhone: {value: '', dataSet: {question: "Cell Phone", isRequired: false, formDataKey: 'cellPhone', isError: false, errorMessage: ''}, type: 'simpleInput', inputType: 'number', disable: true,placeholder:'Cell Phone Number'},
      
        homePhone: {value: '', dataSet: {question: "Home Phone", isRequired: false, formDataKey: 'homePhone', isError: false, errorMessage: ''}, type: 'simpleInput', inputType: 'number', disable: true,placeholder:'Home Phone Number'},

        course: {value: '', dataSet: {question: "Course Title", isRequired: true, formDataKey: 'course', isError: false, errorMessage: ''}, type: 'dropdown', disable: true,placeholder:'Please Select Course'},

        // New
        NYSERDA_priorityPopulations: {value: [], dataSet: {question: "Do you identify with any of the following? (Select all that apply to you)", options: ["Veterans", "Native Americans", "Individuals with disabilities", "Immigrant", "Refugee", "English as a second language (ESL)", "1st Generation College", "2nd Generation College", "Low income individuals - (60% of NYS median income or receiving public assistance)", "Unemployed plant workers", "Previously incarcerated Individuals", "Homeless Individuals", "16 to 24 year olds in work preparedness training programs that include energy related technical Training", "Single parents", "Displaced Homemakers", "Participants in Work Preparedness Training Programs", "Incumbent or unemployed fossil fuel workers", "None of the above"], isRequired: true, groupName: 'NYSERDA_priorityPopulations', formDataKey: 'NYSERDA_priorityPopulations', isError: false, errorMessage: ''}, type: 'simpleCheckbox', disable: true},

        // New
        sex: {value: '', city: '', stateShortcode: '', dataSet: {question: "Which of the following do you most closely identify with?", options: ["Male", "Female", "Non-binary", "I prefer not to say"], isRequired: true, groupName: 'sex', formDataKey: 'sex', isError: false, errorMessage: ''},type: 'simpleRadio', disable: true},

        // New
        pronoun: {value: '', dataSet: {question: "How would you like to be addressed (your pronouns)?", options: ["He/Him", "She/Her", "They/Them", "Other"], isRequired: false, groupName: 'pronoun', formDataKey: 'pronoun', isError: false, errorMessage: ''},type: 'radioWithOther', disable: true},

        // New
        ethnicity: {value: '', dataSet: {question: "Which of the following ethnicities do you most closely identify with?", options: ["American Indian or Alaska Native (Eg: Navajo nation, Blackfeet tribe, Mayan, Aztec, Native Village or Barrow Inupiat Traditional Government, Nome Eskimo Community, etc)", "Asian (Eg: Chinese, Filipino, Asian Indian, Vietnamese, Korean, Japanese, etc)", "Black or African American (Eg: African American, Jamaican, Haitian, Nigerian, Ethiopian, Somalian, etc)", "Hispanic, Latino or Spanish (Eg: Mexican or Mexican American, Puerto Rican, Cuban, Salvadoran, Dominican, Colombian, etc)", "Middle Eastern or North African (Eg: Lebanese, Iranian, Egyptian, Syrian, Moroccan, Algerian, etc)", "Native Hawaiian or Other Pacific Islander (Eg: Native Hawaiian, Samoan, Chamorro, Tongan, Fijian, etc)", "White (Eg: German, Irish, English, Italian, Polish, French, etc)", "I prefer not to say"], isRequired: true, groupName: 'ethnicity', formDataKey: 'ethnicity', isError: false, errorMessage: ''},type: 'simpleRadio', disable: true},

        // Removed
        // dateOfCourse: {value:'',dataSet:{question:"Course Date",isRequired:true, formDataKey:'dateOfCourse',isError: false, errorMessage: ''}, type: 'dob', disable: true},

        // Removed
        // TrainingOrganization: {value:'',dataSet:{question:"Training Organization",isRequired:true, formDataKey:'TrainingOrganization',isError: false, errorMessage: ''}, type: 'simpleInput', inputType: 'text', disable: true,placeholder:'Training Organization'},

        // Removed
        // TrainerName: {value:'',dataSet:{question:"Trainer Name(s)",isRequired:true, formDataKey:'TrainerName',isError: false, errorMessage: ''}, type: 'simpleInput', inputType: 'text', disable: true,placeholder:'Trainer Name(s)'},

        isEmployed: {value: '', dataSet: {question: "Are you employed?", options: ["Yes", "No"], isRequired: true, groupName: 'isEmployed', formDataKey: 'isEmployed', isError: false, errorMessage: ''}, type: 'simpleRadio', disable: true}, 
        
        isSelfEmployed: {value: '', dataSet: {question: "If employed, are you self-employed?", options: ["Yes", "No"], isRequired: true, groupName: 'isSelfEmployed', formDataKey: 'isSelfEmployed', isError: false, errorMessage: ''}, type: 'simpleRadio', disable: true, isVisible: false}, 
        
        employedWorkingType: {value: '', dataSet: {question: "If employed, are you working Full-Time or Part-Time?", options: ["Full-Time", "Part-Time"], isRequired: true, groupName: 'employedWorkingType', formDataKey: 'employedWorkingType', isError: false, errorMessage: ''}, type: 'simpleRadio', disable: true, isVisible: false}, 
        
        employedWorkingIn: {value: '', dataSet: {question: "If employed, are you working in a role and/or company related to clean energy?", options: ["Yes", "No"], isRequired: true, groupName: 'employedWorkingIn', formDataKey: 'employedWorkingIn', isError: false, errorMessage: ''}, type: 'simpleRadio', disable: true, isVisible: false}, 
        
        isStudiedCleanEnergy: {value: '', dataSet: {question: "Did you study in the clean energy or related field?", options: ["Yes", "No"], isRequired: true, groupName: 'isStudiedCleanEnergy', formDataKey: 'isStudiedCleanEnergy', isError: false, errorMessage: ''}, type: 'simpleRadio', disable: true, isVisible: false}, 
        
        IsWorking: {value: '', dataSet: {question: "Are you working in the area of your training or study?", options: ["Yes", "No"], isRequired: true, groupName: 'IsWorking', formDataKey: 'IsWorking', isError: false, errorMessage: ''}, type: 'simpleRadio', disable: true, isVisible: false}, 
        
        // Changes
        currentEmployer: {value: '', dataSet: {question: "Who is your current or last employer?", isRequired: true, groupName: 'currentEmployer', formDataKey: 'currentEmployer', isError: false, errorMessage: ''}, type: 'simpleInput', disable: true},
        
        companyJoiningDate: {value: '', dataSet: {question: "When did you start working with this company?", isRequired: true, groupName: 'companyJoiningDate', formDataKey: 'companyJoiningDate', isError: false, errorMessage: ''}, type: 'dob', disable: true, isVisible: false}, 
        
        // dateOfBirth: {value: '', dataSet: {question: "Will you be at least 18 years old as of the start of course?", options: ["Yes", "No"], isRequired: true, groupName: 'dateOfBirth', formDataKey: 'dateOfBirth', isError: false, errorMessage: ''},type: 'simpleRadio', disable: true}, 
        employmentMangement: {value: '', dataSet: {question: "What is your current or last job title or position?", isRequired: true, formDataKey: 'employmentMangement', isError: false, errorMessage: ''}, type: 'simpleInput', inputType: 'text', placeholder:'Job Title'},
        
        currentSalaryRange: {value: '', dataSet: {question: "What is your current salary range?", options: ['less than $30k','$30k - $44k','$45k - $59k','$60k - $74k','$75k - $89k','$90k - $105k', 'more than $105k'], isRequired: true, groupName: 'currentSalaryRange', formDataKey: 'currentSalaryRange', isError: false, errorMessage: ''}, type: 'simpleRadio', disable: true},
        
        // New
        expectedSalaryRange: {value: '', dataSet: {question: "What is your expected salary range?", options: ['less than $30k','$30k - $44k','$45k - $59k','$60k - $74k','$75k - $89k','$90k - $105k', 'more than $105k'], isRequired: true, groupName: 'expectedSalaryRange', formDataKey: 'expectedSalaryRange', isError: false, errorMessage: ''}, type: 'simpleRadio', disable: true},

        lookingEmployment: {value: '', dataSet: {question: "Are you looking for employment opportunities?", options: ["Yes", "No"], isRequired: true, groupName: 'lookingEmployment', formDataKey: 'lookingEmployment', isError: false, errorMessage: ''}, type: 'simpleRadio', disable: true}, 
        // If above question is Yes ---------------------------------
        lookingEmploymentDetails: {value: '', dataSet: {question: "If yes, select which one fits you:", options: ["Full-Time", "Part-Time", "Both"], isRequired: true, groupName: 'lookingEmploymentDetails', formDataKey: 'lookingEmploymentDetails', isError: false, errorMessage: ''}, type: 'simpleRadio', disable: true, isVisible: false}, 

        // Removed
        // openToIntership: {value: '', dataSet: {question: "Are you open to internship opportunities?", options: ["Yes", "No"], isRequired: true, groupName: 'openToIntership', formDataKey: 'openToIntership', isError: false, errorMessage: ''}, type: 'simpleRadio', disable: true, isVisible: false}, 

        // New
        technologiesExperience : {
            "value": [],
            "otherValue": "",
            "dataSet": {
            "question": "Which technologies do you have Experience working in? (Select all that apply to you)",
            "options": [
                {
                "option": "Lighting",
                "children": ["Entry Level", "Mid Level", "Advanced"],
                "showChildren": false,
                "subValue": "Entry Level",
                "groupName": "lighting"
                },
                {
                "option": "HVAC",
                "children": ["Entry Level", "Mid Level", "Advanced"],
                "showChildren": false,
                "subValue": "Entry Level",
                "groupName": "hvac"
                },
                {
                "option": "Appliances",
                "children": ["Entry Level", "Mid Level", "Advanced"],
                "showChildren": false,
                "subValue": "Entry Level",
                "groupName": "appliances"
                },
                {
                "option": "Motors and Drives",
                "children": ["Entry Level", "Mid Level", "Advanced"],
                "showChildren": false,
                "subValue": "Entry Level",
                "groupName": "motorsAndDrives"
                },
                {
                "option": "Heating",
                "children": ["Entry Level", "Mid Level", "Advanced"],
                "showChildren": false,
                "subValue": "Entry Level",
                "groupName": "heating"
                },
                {
                "option": "Hot Water",
                "children": ["Entry Level", "Mid Level", "Advanced"],
                "showChildren": false,
                "subValue": "Entry Level",
                "groupName": "hotWater"
                },
                {
                "option": "Building Envelope",
                "children": ["Entry Level", "Mid Level", "Advanced"],
                "showChildren": false,
                "subValue": "Entry Level",
                "groupName": "buildingEnvelope"
                },
                {
                "option": "Combustion Appliances",
                "children": ["Entry Level", "Mid Level", "Advanced"],
                "showChildren": false,
                "subValue": "Entry Level",
                "groupName": "combustionAppliances"
                },
                {
                "option": "Solar PV",
                "children": ["Entry Level", "Mid Level", "Advanced"],
                "showChildren": false,
                "subValue": "Entry Level",
                "groupName": "solarPV"
                },
                {
                "option": "Wind",
                "children": ["Entry Level", "Mid Level", "Advanced"],
                "showChildren": false,
                "subValue": "Entry Level",
                "groupName": "wind"
                },
                {
                "option": "Solar Thermal",
                "children": ["Entry Level", "Mid Level", "Advanced"],
                "showChildren": false,
                "subValue": "Entry Level",
                "groupName": "solarThermal"
                },
                {
                "option": "Geothermal",
                "children": ["Entry Level", "Mid Level", "Advanced"],
                "showChildren": false,
                "subValue": "Entry Level",
                "groupName": "geothermal"
                },
                {
                "option": "None",
                "children": [],
                "showChildren": false,
                "subValue": "Entry Level",
                "groupName": "none"
                },
                {
                "option": "Other",
                "children": [],
                "showChildren": false,
                "subValue": "Entry Level",
                "groupName": "other"
                }
            ],
            "isRequired": true,
            "groupName": "technologiesExperience",
            "formDataKey": "technologiesExperience",
            "isError": false,
            "errorMessage": "",
            "isNoneSelect": false,
            "isOtherSelect": false
            },
            "type": "checkboxWithExpander",
            "disable": true
        },

        // New
        buildingSectorExperience : {
            "value": [],
            "otherValue": "",
            "dataSet": {
                "question": "Which building sectors do you have Experience working in? (Select all that apply to you)",
                "options": [
                    {
                        "option": 'Small Residential ("Small Homes")',
                        "children": ["Entry Level", "Mid Level", "Advanced"],
                        "showChildren": false,
                        "subValue": "Entry Level",
                        "groupName": "smallResidential"
                    },
                    {
                        "option": 'Large Residential ("Multifamily")',
                        "children": ["Entry Level", "Mid Level", "Advanced"],
                        "showChildren": false,
                        "subValue": "Entry Level",
                        "groupName": "largeResidential"
                    },
                    {
                        "option": 'Small Commercial ("Small and Medium Business")',
                        "children": ["Entry Level", "Mid Level", "Advanced"],
                        "showChildren": false,
                        "subValue": "Entry Level",
                        "groupName": "smallCommercial"
                    },
                    {
                        "option": 'Large Commercial ("Commercial & Industrial")',
                        "children": ["Entry Level", "Mid Level", "Advanced"],
                        "showChildren": false,
                        "subValue": "Entry Level",
                        "groupName": "largeCommercial"
                    }
                ],
                "isRequired": true,
                "groupName": "buildingSectorExperience",
                "formDataKey": "buildingSectorExperience",
                "isError": false,
                "errorMessage": "",
                "isNoneSelect": false,
                "isOtherSelect": false
            },
            "type": "checkboxWithExpander",
            "disable": true
        },

        // New
        roleExperience: {
          "value": [],
          "otherValue": "",
          "dataSet": {
              "question": "Which roles do you have Experience working in? (Select all that apply to you)",
              "options": [
                  {
                      "option": "Sales & Outreach",
                      "children": ["Entry Level", "Mid Level", "Advanced"],
                      "showChildren": false,
                      "subValue": "Entry Level",
                      "groupName": "salesOutreach"
                  },
                  {
                      "option": "Auditor & Junior Engineer",
                      "children": ["Entry Level", "Mid Level", "Advanced"],
                      "showChildren": false,
                      "subValue": "Entry Level",
                      "groupName": "auditorJuniorEngineer"
                  },
                  {
                      "option": "Finance & Incentive Admin",
                      "children": ["Entry Level", "Mid Level", "Advanced"],
                      "showChildren": false,
                      "subValue": "Entry Level",
                      "groupName": "financeIncentiveAdmin"
                  },
                  {
                      "option": "Project Management & Project Coordination",
                      "children": ["Entry Level", "Mid Level", "Advanced"],
                      "showChildren": false,
                      "subValue": "Entry Level",
                      "groupName": "projectManagementCoordination"
                  },
                  {
                      "option": "Design & Engineering",
                      "children": ["Entry Level", "Mid Level", "Advanced"],
                      "showChildren": false,
                      "subValue": "Entry Level",
                      "groupName": "designEngineering"
                  },
                  {
                      "option": "Installation & Contracting",
                      "children": ["Entry Level", "Mid Level", "Advanced"],
                      "showChildren": false,
                      "subValue": "Entry Level",
                      "groupName": "installationContracting"
                  },
                  {
                      "option": "Site Inspector",
                      "children": ["Entry Level", "Mid Level", "Advanced"],
                      "showChildren": false,
                      "subValue": "Entry Level",
                      "groupName": "siteInspector"
                  },
                  {
                      "option": "Operations & Maintenance",
                      "children": ["Entry Level", "Mid Level", "Advanced"],
                      "showChildren": false,
                      "subValue": "Entry Level",
                      "groupName": "operationsMaintenance"
                  },
                  {
                      "option": "Clean Energy Instructor",
                      "children": ["Entry Level", "Mid Level", "Advanced"],
                      "showChildren": false,
                      "subValue": "Entry Level",
                      "groupName": "cleanEnergyInstructor"
                  },
                  {
                      "option": "No Preference",
                      "children": ["Entry Level", "Mid Level", "Advanced"],
                      "showChildren": false,
                      "subValue": "Entry Level",
                      "groupName": "noPreference"
                  },
                  {
                    "option": "Other",
                    "children": [],
                    "showChildren": false,
                    "subValue": "Entry Level",
                    "groupName": "other"
                  }
              ],
              "isRequired": true,
              "groupName": "roleExperience",
              "formDataKey": "roleExperience",
              "isError": false,
              "errorMessage": "",
              "isNoneSelect": false,
              "isOtherSelect": false
            },
          "type": "checkboxWithExpander",
          "disable": true
        },

        // New
        interestTechnologies: {value: [], otherValue: '', dataSet: {question: "Do you have Interest in those technologies?", options:["Lighting", "HVAC", "Appliances", "Motors and Drives", "Heating", "Hot Water", "Building Envelope", "Combustion Appliances", "Solar PV", "Wind", "Solar Thermal", "Geothermal", "None of the above", "Other"], isRequired: true, groupName: 'interestTechnologies', formDataKey: 'interestTechnologies', isError: false, errorMessage: ''}, type: 'checkboxWithOther', disable: true, limitOptions: 3}, 
        
        // New
        interestBuildingSector: {value: [], dataSet: {question: "Do you have Interest in those building sectors?", options:['Small Residential ("Small Homes")', 'Large Residential ("Multifamily")', 'Small Commercial ("Small and Medium Business")', 'Large Commercial ("Commercial & Industrial")'], isRequired: true, groupName: 'interestBuildingSector', formDataKey: 'interestBuildingSector', isError: false, errorMessage: ''}, type: 'simpleCheckbox', disable: true, limitOptions: 2}, 
        
        // New
        interestRole: {value: [], dataSet: {question: "Do you have Interest in these roles?", options:["Sales & Outreach", "Auditor & Junior Engineer", "Finance & Incentive Admin", "Project Management & Project Coordination", "Design & Engineering", "Installation & Contracting", "Site Inspector", "Operations & Maintenance", "Clean Energy Instructor", "No Preference"], isRequired: true, groupName: 'interestRole', formDataKey: 'interestRole', isError: false, errorMessage: ''}, type: 'simpleCheckbox', disable: true, limitOptions: 3},
        
        
        reasonEnroll: {value: [], otherValue: '', dataSet: {question: "What is your main educational objective for taking this class?", options:["Learn new skills or knowledge in this field", "Refresh and reinforce knowledge or skills I have learned before", "Meet professional requirements (e.g., certification, license, or mandated program)", "Advance my career (e.g., promotion, better job, or new opportunities)", "Meet the needs of my employer, client, or customer", "Other"], isRequired: true, groupName: 'reasonEnroll', formDataKey: 'reasonEnroll', isError: false, errorMessage: ''}, type: 'checkboxWithOther', disable: true},
        
        educationLevel: {value: '', dataSet: {question: "What is your highest level of completed education? (Select the one that most applies to you)", options: ["Did not complete High School", "High School/HSE/GED", "Associates Degree", "Bachelors Degree", "Graduate Degree", "Post Graduate"], isRequired: true, groupName: 'educationLevel', formDataKey: 'educationLevel', isError: false, errorMessage: ''}, type: 'simpleRadio', disable: true}, 

        participatedVocationalProgram: {value: '', dataSet: {question: "Have you ever participated or are you currently participating in a vocational program?", options: ["Yes, I am currently", "Yes, I have in the past", "Yes, I have in the past and yes I am currently", "No"], isRequired: true, groupName: 'participatedVocationalProgram', formDataKey: 'participatedVocationalProgram', isError: false, errorMessage: ''}, type: 'simpleRadio', disable: true}, 

        expectationFromCourse: {value: [], otherValue: '', dataSet: {question: "What's your expectation regarding the rigor of the course? (Select all that apply to you) ", options:['The course may be over my head', 'The course may be below my level', 'The course is definitely over my head', 'The course is definitely below my level', 'I am not sure', 'Other'], isRequired: true, groupName: 'expectationFromCourse', formDataKey: 'expectationFromCourse', isError: false, errorMessage: ''}, type: 'checkboxWithOther', disable: true},
        
        courselanguage: {value: '', dataSet: {question: "If we could offer our course in another language, which would you prefer? (Select the one that most applies to you)", options: ["English", "Spanish", "Mandarin", "Russian", "Polish", "Other"], isRequired: true, groupName: 'courselanguage', formDataKey: 'courselanguage', isError: false, errorMessage: ''}, type: 'simpleRadioWithOther', disable: true}, 

        // Remove
        // infoSourceOfJobOpp:{value: [], otherValue: '', dataSet: {question: "What information source do you use to find job opportunities?", options:[`Clean Energy Academy Job Board (http://cleanenergyacademy.org)`,'NYS Department of Labor Career Center','Training provider','“Help wanted” ads in a newspaper','Internet (e.g. Monster.com)','Guidance Counselor','Networking',"HRA / Workforce One",'I am not sure','Other'], isRequired: true, groupName: 'infoSourceOfJobOpp', formDataKey: 'infoSourceOfJobOpp', isError: false, errorMessage: ''}, type: 'checkboxWithOther', disable: true}, 

        // New
        jobTitle: {value: '', dataSet: {question: "What's your ideal job title?", isRequired: true, formDataKey: 'jobTitle', isError: false, errorMessage: ''}, type: 'simpleInput', inputType: 'text', disable: true}, 


        // ------------------------------------------------------
        
        // New
        companySize: {value: '', dataSet: {question: "What company size would you prefer to work in?", options: ["Small (under 100 employees)", "Medium (between 100 - 999 employees)", "Large (over 1000 employees)"], isRequired: true, groupName: 'companySize', formDataKey: 'companySize', isError: false, errorMessage: ''}, type: 'simpleRadio', disable: true},
        
        // New
        jobType: {value: [], dataSet: {question: "What job type would you prefer to work?", options: ["Volunteer", "Internship", "Employee (W2)", "Contractor (1099)"], isRequired: true, groupName: 'jobType', formDataKey: 'jobType', isError: false, errorMessage: ''}, type: 'simpleCheckbox', disable: true},
        
        // New
        workCulture: {value: '', dataSet: {question: "What type of work culture would you prefer to work in?", options: ["Casual", "Corporate"], isRequired: true, groupName: 'workCulture', formDataKey: 'workCulture', isError: false, errorMessage: ''}, type: 'simpleRadio', disable: true},
        
        // New
        workBenefit: {value: '', dataSet: {question: "What kind of work benefit would you prefer?", options: ["Basic", "Comprehensive"], isRequired: true, groupName: 'workBenefit', formDataKey: 'workBenefit', isError: false, errorMessage: ''}, type: 'simpleRadio', disable: true},
        
        // New
        workLocation: {value: [], dataSet: {question: "What type of work location would you prefer to work?", options: ["On-site", "Hybrid", "Remote"], isRequired: true, groupName: 'workLocation', formDataKey: 'workLocation', isError: false, errorMessage: ''}, type: 'simpleCheckbox', disable: true},
        
        // New
        workSector: {value: [], dataSet: {question: "What sector would you prefer to work for?", options: ["Private (Company or Corporation)", "Public (Government Agency)", "Non-Profit (Community-based Organization)"], isRequired: true, groupName: 'workSector', formDataKey: 'workSector', isError: false, errorMessage: ''}, type: 'simpleCheckbox', disable: true},
        
        idFile: {value:'', dataSet:{question: "Please provide a valid form of identification such as a Driver's License, State ID, Passport, School ID, or Foreign Passport:", isRequired: false, formDataKey: 'idFile', isError: false, errorMessage: '',allowedFileType: ['png', 'pdf', 'jpg','jpeg'], isOption:true}, disable: true, type: 'upload', inputType: 'text'},
      }
  )

  const [IsLoading, setIsLoading] = useState(false)
  const validateData = () => {
    let functionReturn = true
    let validateFormData = formData
    // console.log("email: ", email, formData['email']['dataSet']['isRequired'] && (formData['email']['dataSet']['formDataKey'] === "email"), validateFormData);

    Object.keys(validateFormData).forEach(eachQuestion => {
      // Checking Required ------------------------------------------------------------------------------------------------------------------
      // console.log(validateFormData[eachQuestion]['dataSet'].isRequired)
      const isNumberError = validateFormData[eachQuestion]['inputType'] === "number" && !(NUMBER_REGEX.test((validateFormData[eachQuestion]?.['value'])))

        if(validateFormData[eachQuestion]['dataSet'].isRequired && (validateFormData[eachQuestion]['type']) ==='groupedradio'){
          const l=[]
          Object.keys(validateFormData[eachQuestion]['dataSet']['choices']).map((i)=>{
            if(validateFormData[eachQuestion]['dataSet']['choices'][i]!=''){
              l.push(validateFormData[eachQuestion]['dataSet']['choices'][i])
            }
          })
          if(l.length != Object.keys(validateFormData[eachQuestion]['dataSet']['choices']).length){
            validateFormData[eachQuestion]['dataSet']['isError'] = true
            validateFormData[eachQuestion]['dataSet']['errorMessage'] = 'Required'
            functionReturn = false
          }
          else{
            validateFormData[eachQuestion]['dataSet']['isError'] = false
            validateFormData[eachQuestion]['dataSet']['errorMessage'] = ''
          }
        } else if (validateFormData[eachQuestion]['dataSet'].isRequired && (validateFormData[eachQuestion]['type']) === 'checkboxWithExpander') {
          const valueArray = validateFormData[eachQuestion]['value']
          if (valueArray.length !== 0) {
            for (let i = 0 ; i < valueArray.length; i++) {
              if (valueArray[i]['value'] === "None") {
                validateFormData[eachQuestion]['dataSet']['isError'] = false
                validateFormData[eachQuestion]['dataSet']['errorMessage'] = ''
                if (valueArray[i]['value'] === "None") {
                  validateFormData[eachQuestion]['value'] = [{value: 'None', subValue: ''}]
                }
              } else {
                if ((valueArray[i]['subValue']).length > 0) {
                  if (valueArray[i]['value'] === "Other") {
                    if ((validateFormData[eachQuestion]['dataSet']['isOtherSelect'] 
                    // && validateFormData[eachQuestion]['otherValue']
                    )) {
                      validateFormData[eachQuestion]['dataSet']['isError'] = false
                      validateFormData[eachQuestion]['dataSet']['errorMessage'] = ''
                    } else {
                      validateFormData[eachQuestion]['dataSet']['isError'] = true
                      validateFormData[eachQuestion]['dataSet']['errorMessage'] = 'Required'
                    }
                  } else {
                    if (validateFormData[eachQuestion]['dataSet']['isOtherSelect'] === false) {
                      validateFormData[eachQuestion]['dataSet']['isError'] = false
                      validateFormData[eachQuestion]['dataSet']['errorMessage'] = ''
                    } else {
                      if ((validateFormData[eachQuestion]['dataSet']['isOtherSelect'] 
                      // && validateFormData[eachQuestion]['otherValue']
                      )) {
                        validateFormData[eachQuestion]['dataSet']['isError'] = false
                        validateFormData[eachQuestion]['dataSet']['errorMessage'] = ''
                      } else {
                        validateFormData[eachQuestion]['dataSet']['isError'] = true
                        validateFormData[eachQuestion]['dataSet']['errorMessage'] = 'Required'
                      }
                    }
                  }
                } else {
                  validateFormData[eachQuestion]['dataSet']['isError'] = true
                  validateFormData[eachQuestion]['dataSet']['errorMessage'] = 'Required'
                  functionReturn = false
                }
              }
            }
          } else {
            validateFormData[eachQuestion]['dataSet']['isError'] = true
            validateFormData[eachQuestion]['dataSet']['errorMessage'] = 'Required'
            functionReturn = false
          }
        } else if (validateFormData[eachQuestion]['dataSet'].isRequired && (validateFormData[eachQuestion]['type'] === 'dropdown')) {
          const selectedValue = validateFormData[eachQuestion]['value']
          if ((`${selectedValue}`).includes('WestCop')) {
            validateFormData['idFile']['dataSet']['isRequired'] = true
          } else {
            validateFormData['idFile']['dataSet']['isRequired'] = false
          }
        } 
        else if(validateFormData[eachQuestion]['dataSet']['isRequired'] && (validateFormData[eachQuestion]['value']) <= 0 || (isNumberError && (`${validateFormData[eachQuestion]?.['value']}`).length > 0)){
          if (!validateFormData[eachQuestion]['dataSet']['isRequired'] && isNumberError && (`${validateFormData[eachQuestion]?.['value']}`).length > 0) {
            validateFormData[eachQuestion]['dataSet']['isError'] = true
            validateFormData[eachQuestion]['dataSet']['errorMessage'] = isNumberError && (`${validateFormData[eachQuestion]?.['value']}`).length > 0 ? 'Invalid format' : 'Required'
            functionReturn = false
          } else {
            validateFormData[eachQuestion]['dataSet']['isError'] = true
            validateFormData[eachQuestion]['dataSet']['errorMessage'] = isNumberError && (`${validateFormData[eachQuestion]?.['value']}`).length > 0 ? 'Invalid format' : 'Required'
            functionReturn = false
          }
        } else {
          validateFormData[eachQuestion]['dataSet']['isError'] = false
          validateFormData[eachQuestion]['dataSet']['errorMessage'] = ''
        }
      // Checking Required ------------------------------------------------------------------------------------------------------------------
      // Push the children questions into the formdata
      // if (!IsLookingEmployed) {
        if (eachQuestion === 'lookingEmployment') {
          if (validateFormData[eachQuestion]['value'] === "Yes") {
            // setIsLookingEmployed(true)
            validateFormData['lookingEmploymentDetails']['isVisible'] = true
            // validateFormData['openToIntership']['isVisible'] = true
            // validateFormData['careerSupport']['isVisible'] = true

            validateFormData['lookingEmploymentDetails']['dataSet']['isRequired'] = true
            // validateFormData['openToIntership']['dataSet']['isRequired'] = true
            // validateFormData['careerSupport']['dataSet']['isRequired'] = true
            
            // isStudiedCleanEnergy
            // IsWorking
            // companyJoiningDate
            
          } else {
            validateFormData['lookingEmploymentDetails']['isVisible'] = false
            validateFormData['lookingEmploymentDetails']['value'] = ''
            validateFormData['lookingEmploymentDetails']['dataSet']['isRequired'] = false 
            
            // validateFormData['openToIntership']['isVisible'] = false
            // validateFormData['openToIntership']['value'] = ''
            // validateFormData['openToIntership']['dataSet']['isRequired'] = false 

            // validateFormData['careerSupport']['isVisible'] = false
            // validateFormData['careerSupport']['value'] = ''
            // validateFormData['careerSupport']['dataSet']['isRequired'] = false 

          }
        }
        
        if (eachQuestion === "isEmployed") {
          if (validateFormData[eachQuestion]['value'] === "Yes") {
            validateFormData['isSelfEmployed']['isVisible'] = true
            validateFormData['employedWorkingType']['isVisible'] = true
            validateFormData['employedWorkingIn']['isVisible'] = true
            // validateFormData['currentEmployer']['isVisible'] = true
            validateFormData['isSelfEmployed']['dataSet']['isRequired'] = true
            validateFormData['employedWorkingType']['dataSet']['isRequired'] = true
            validateFormData['employedWorkingIn']['dataSet']['isRequired'] = true
            
            validateFormData['isStudiedCleanEnergy']['isVisible'] = true
            validateFormData['IsWorking']['isVisible'] = true
            validateFormData['companyJoiningDate']['isVisible'] = true
    
            validateFormData['isStudiedCleanEnergy']['dataSet']['isRequired'] = true
            validateFormData['IsWorking']['dataSet']['isRequired'] = true
            validateFormData['companyJoiningDate']['dataSet']['isRequired'] = true
          } else {
            validateFormData['isSelfEmployed']['isVisible'] = false
            validateFormData['isSelfEmployed']['value'] = ''
            validateFormData['isSelfEmployed']['dataSet']['isRequired'] = false

            validateFormData['employedWorkingType']['isVisible'] = false
            validateFormData['employedWorkingType']['value'] = ''
            validateFormData['employedWorkingType']['dataSet']['isRequired'] = false

            validateFormData['employedWorkingIn']['isVisible'] = false
            validateFormData['employedWorkingIn']['value'] = ''
            validateFormData['employedWorkingIn']['dataSet']['isRequired'] = false
 
            validateFormData['isStudiedCleanEnergy']['isVisible'] = false
            validateFormData['isStudiedCleanEnergy']['value'] = ''
            validateFormData['isStudiedCleanEnergy']['dataSet']['isRequired'] = false 
    
            validateFormData['IsWorking']['isVisible'] = false
            validateFormData['IsWorking']['value'] = ''
            validateFormData['IsWorking']['dataSet']['isRequired'] = false 
    
            validateFormData['companyJoiningDate']['isVisible'] = false
            validateFormData['companyJoiningDate']['value'] = ''
            validateFormData['companyJoiningDate']['dataSet']['isRequired'] = false 
          }
        }
      // }
    })

    console.log("validateFormData: ", validateFormData);

    setIsLoading(true)
    setTimeout(() => {
      setIsLoading(false)
    }, 500)
    setFormData(validateFormData)
    return functionReturn
  }
      
  const handleReloadForm = () => {
    setModalName('Thank You')
    setTimeout(() => {
      window.location.reload()
    }, 3000)
  }
  
  const handleCancel = () => {
    setShow(false)
  }

  // // Format nyserda
  // const originalNyserdaValues = (userData.NYSERDA_priorityPopulations).split(',').map(element => element.trim())

  // let nyserdaValue = [];
  // Object.keys(NYSERDA_priorityPopulationsDynamic).map(key => {
  //   originalNyserdaValues.forEach(value => {
  //     if ((NYSERDA_priorityPopulationsDynamic[key]).includes(value) || (NYSERDA_priorityPopulationsDynamic[key]).includes(value.substring(0, 2))) {
  //       nyserdaValue.push(key)
  //     }
  //   })
  // })

  const handleSubmit = () => {
    if (validateData()) {
      let dateString = new Date();
      dateString = moment(dateString).format('YYYY-MM-DD');

      // Make Data For Question Type == CheckboxWithExpander

      let expander1 = formData.technologiesExperience.value
      let technologiesExperience = {} 
      expander1.forEach((item, i) => {
        technologiesExperience[i] = item
      })

      let buildingSectorExperience = formData.buildingSectorExperience.value
      let expander2 = {} 
      buildingSectorExperience.forEach((item, i) => {
        expander2[i] = item
      })

      let roleExperience = formData.roleExperience.value
      let expander3 = {} 
      roleExperience.forEach((item, i) => {
        expander3[i] = item
      })

      let interestTechnologies = formData.interestTechnologies.value
      let expander4 = {} 
      interestTechnologies.forEach((item, i) => {
        expander4[i] = item
      })
     
      let interestBuildingSector = formData.interestBuildingSector.value
      let expander5 = {} 
      interestBuildingSector.forEach((item, i) => {
        expander5[i] = item
      })
     
      let interestRole = formData.interestRole.value
      let expander6 = {} 
      interestRole.forEach((item, i) => {
        expander6[i] = item
      })

      // NYSERDA Conditions -----------------------------------------
      const category1 = ["Veterans", "Native Americans", "Disabled", "Low income", "Unemployed plant workers", "Previously incarcerated", "Homeless", "16-24 years olds in WFD program", "Single parents"]
      const category2 = ["Immigrant", "Refugee", "ESL", "1st Generation College", "2nd Generation College", "Displaced Homemakers", "Participants in Work Preparedness Training Programs", "Incumbent or unemployed fossil fuel workers"]
      
      const updatedNyserdaOptions = formData['NYSERDA_priorityPopulations']['value']

      formData['NYSERDA_priorityPopulations']['value'].forEach((item, i) => {
        if (NYSERDA_priorityPopulationsDynamic[item]) {
          updatedNyserdaOptions[i] = NYSERDA_priorityPopulationsDynamic[item]
        }
      })

      let nyserdaQuestion = updatedNyserdaOptions
      const finalNyserdaValues = {
        NYSERDA_priorityPopulations: [],
        CEA_priorityPopulations: []
      }

      if (Array.isArray(nyserdaQuestion)) {
        nyserdaQuestion.forEach((value) => {
          if (category1.includes(value)) {
            finalNyserdaValues.NYSERDA_priorityPopulations = [...finalNyserdaValues.NYSERDA_priorityPopulations, value]
          }

          if (category2.includes(value)) {
            finalNyserdaValues.CEA_priorityPopulations = [...finalNyserdaValues.CEA_priorityPopulations, value]
          }
        })
      }
      
      finalNyserdaValues.NYSERDA_priorityPopulations = (finalNyserdaValues.NYSERDA_priorityPopulations || []).length === 0 ? "None" : (finalNyserdaValues.NYSERDA_priorityPopulations).map(item => item).join(',')
      finalNyserdaValues.CEA_priorityPopulations = (finalNyserdaValues.CEA_priorityPopulations || []).length === 0 ? "None" : (finalNyserdaValues.CEA_priorityPopulations).map(item => item).join(',')  
      // NYSERDA Conditions -----------------------------------------

      if (checked) {
        if (agreeWithAgreement) {
          setLoader(true)
          NyserdaPreTrainingFormApplications({data: {
            email: formData['email']['value'],
            fName: formData['fName']['value'],
            lName: formData['lName']['value'],
            // dateOfBirth:formData['dateOfBirth']['value'],
            cellPhone: formData['cellPhone']['value'],
            homePhone:formData['homePhone']['value'],
            course:formData['course']['value'],
            // dateOfCourse:formData['dateOfCourse']['value'],
            // TrainingOrganization:formData['TrainingOrganization']['value'],
            // TrainerName:formData['TrainerName']['value'],

            employmentMangement: formData['employmentMangement']['value'],
            educationLevel: formData['educationLevel']['value'],
            reasonEnroll: formData['reasonEnroll']['value'].map(item => item).join(',') + formData['reasonEnroll']['otherValue'],
            // infoSourceOfJobOpp:(formData['infoSourceOfJobOpp']['value']).map(item => item).join(','),
            expectationFromCourse: (formData['expectationFromCourse']['value']).map(item => item).join(','),
            versionName: '2025',
            studentMoodleId: formData?.email?.studentMoodleId || '',
            
            currentSalaryRange:formData['currentSalaryRange']['value'],
            expectedSalaryRange:formData['expectedSalaryRange']['value'],

            idFile:formData['idFile']['value'],
            isEmployed: formData['isEmployed']['value'],
            isSelfEmployed: formData['isSelfEmployed']['value'],
            employedWorkingType: formData['employedWorkingType']['value'],
            employedWorkingIn: formData['employedWorkingIn']['value'],
            isStudiedCleanEnergy: formData['isStudiedCleanEnergy']['value'],
            IsWorking: formData['IsWorking']['value'],
            currentEmployer: formData['currentEmployer']['value'],
            companyJoiningDate: formData['companyJoiningDate']['value'],
            lookingEmployment: formData['lookingEmployment']['value'],
            lookingEmploymentDetails: formData['lookingEmploymentDetails']['value'],
            openToIntership: '',

            technologiesExperience: {...expander1, otherValue: formData.technologiesExperience.otherValue},
            buildingSectorExperience: {...expander2, otherValue: formData.buildingSectorExperience.otherValue},
            roleExperience: {...expander3, otherValue: formData.roleExperience.otherValue},
            interestTechnologies: formData['interestTechnologies']['value'].map(item => item).join(',') + formData['interestTechnologies']['otherValue'],
            interestBuildingSector: formData['interestBuildingSector']['value'].map(item => item).join(','),
            interestRole: formData['interestRole']['value'].map(item => item).join(','),

            participatedVocationalProgram: formData['participatedVocationalProgram']['value'],
            courselanguage: formData['courselanguage']['value'],

            companySize: formData['companySize']['value'],
            workCulture: formData['workCulture']['value'],
            workBenefit: formData['workBenefit']['value'],

            jobTitle: formData['jobTitle']['value'],
            jobType: (formData['jobType']['value']).map(item => item).join(','),
            workLocation: (formData['workLocation']['value']).map(item => item).join(','),
            workSector: (formData['workSector']['value']).map(item => item).join(','),

            agreeStudentAgreement: agreeWithAgreement,
            readStudentAgreement: agreementRead,
            agreementLink: fileDate?.fileLink || "",

            agreeMediaForm: mediaFeature === 'Yes',
            readMediaForm: agreementRead1,
            mediaFormLink: fileDate1?.fileLink || "",

            // New
            NYSERDA_priorityPopulations: finalNyserdaValues.NYSERDA_priorityPopulations || '',
            CEA_priorityPopulations: finalNyserdaValues.CEA_priorityPopulations || '',

            // New
            sex: formData['sex']['value'],

            // New
            pronoun: formData['pronoun']['value'],

            // New
            ethnicity: ethnicitiesDynamic[formData['ethnicity']['value']] ? ethnicitiesDynamic[formData['ethnicity']['value']] : formData['ethnicity']['value'],

            formLanguage: localStorage.getItem('newFormFromLanguage') || 'en',
            userData: userSystemInfo
          }}).then(res => {
            if (res !== null) {
              setShow(true)
              setModalName("Verify Your Email")
              setLoader(false)
              toast.success(<Translate>Application Submitted Successfully</Translate>)
              handleReloadForm()
            }
          }).catch(error => {
            console.log("error: ", error);
            setLoader(false)
            toast.error(<Translate>Something went wrong</Translate>, {position: 'top-right', autoClose: 2000, hideProgressBar: true})
          })
        } else {
          setLoader(false)
          toast.error(<Translate>Please agree with the agreement</Translate>, {position: 'top-right', autoClose: 2000, hideProgressBar: true})
        }
      } else if (checked1) {
        setLoader(true)
        NyserdaPreTrainingFormApplications({data: {
            email: formData['email']['value'],
            fName: formData['fName']['value'],
            lName: formData['lName']['value'],
            // dateOfBirth:formData['dateOfBirth']['value'],
            cellPhone: formData['cellPhone']['value'],
            homePhone:formData['homePhone']['value'],
            course:formData['course']['value'],
            // dateOfCourse:formData['dateOfCourse']['value'],
            // TrainingOrganization:formData['TrainingOrganization']['value'],
            // TrainerName:formData['TrainerName']['value'],

            employmentMangement:formData['employmentMangement']['value'],
            educationLevel:formData['educationLevel']['value'],
            reasonEnroll:formData['reasonEnroll']['value'].map(item => item).join(',') + formData['reasonEnroll']['otherValue'],
            // infoSourceOfJobOpp:(formData['infoSourceOfJobOpp']['value']).map(item => item).join(','),
            expectationFromCourse:(formData['expectationFromCourse']['value']).map(item => item).join(','),
            versionName: '2025',
            studentMoodleId: formData?.email?.studentMoodleId || '',
            
            currentSalaryRange:formData['currentSalaryRange']['value'],
            expectedSalaryRange:formData['expectedSalaryRange']['value'],

            idFile:formData['idFile']['value'],
            isEmployed: formData['isEmployed']['value'],
            isSelfEmployed: formData['isSelfEmployed']['value'],
            employedWorkingType: formData['employedWorkingType']['value'],
            employedWorkingIn: formData['employedWorkingIn']['value'],
            isStudiedCleanEnergy: formData['isStudiedCleanEnergy']['value'],
            IsWorking: formData['IsWorking']['value'],
            currentEmployer: formData['currentEmployer']['value'],
            companyJoiningDate: formData['companyJoiningDate']['value'],
            lookingEmployment: formData['lookingEmployment']['value'],
            lookingEmploymentDetails: formData['lookingEmploymentDetails']['value'],
            openToIntership: '',

            technologiesExperience: {...expander1, otherValue: formData.technologiesExperience.otherValue},
            buildingSectorExperience: {...expander2, otherValue: formData.buildingSectorExperience.otherValue},
            roleExperience: {...expander3, otherValue: formData.roleExperience.otherValue},
            interestTechnologies: formData['interestTechnologies']['value'].map(item => item).join(',') + formData['interestTechnologies']['otherValue'],
            interestBuildingSector: formData['interestBuildingSector']['value'].map(item => item).join(','),
            interestRole: formData['interestRole']['value'].map(item => item).join(','),

            participatedVocationalProgram: formData['participatedVocationalProgram']['value'],
            courselanguage: formData['courselanguage']['value'],

            companySize: formData['companySize']['value'],
            workCulture: formData['workCulture']['value'],
            workBenefit: formData['workBenefit']['value'],
            
            jobTitle: formData['jobTitle']['value'],
            jobType: (formData['jobType']['value']).map(item => item).join(','),
            workLocation: (formData['workLocation']['value']).map(item => item).join(','),
            workSector: (formData['workSector']['value']).map(item => item).join(','),


            agreeStudentAgreement: agreeWithAgreement,
            readStudentAgreement: agreementRead,
            agreementLink: fileDate?.fileLink || "",

            agreeMediaForm: mediaFeature === 'Yes',
            readMediaForm: agreementRead1,
            mediaFormLink: fileDate1?.fileLink || "",

            // New
            NYSERDA_priorityPopulations: finalNyserdaValues.NYSERDA_priorityPopulations || '',
            CEA_priorityPopulations: finalNyserdaValues.CEA_priorityPopulations || '',

            // New
            sex: formData['sex']['value'],

            // New
            pronoun: formData['pronoun']['value'],

            // New
            ethnicity: ethnicitiesDynamic[formData['ethnicity']['value']] ? ethnicitiesDynamic[formData['ethnicity']['value']] : formData['ethnicity']['value'],

            formLanguage: localStorage.getItem('newFormFromLanguage') || 'en',
            userData: userSystemInfo
        }}).then(res => {
          if (res !== null) {
            setShow(true)
            setModalName("Verify Your Email")
            setLoader(false)
            toast.success(<Translate>Application Submitted Successfully</Translate>)
            handleReloadForm()
          }
        }).catch(error => {
          console.log("error: ", error);
          setLoader(false)
          toast.error(<Translate>Something went wrong</Translate>, {position: 'top-right', autoClose: 2000, hideProgressBar: true})
        })
      } else {
        setLoader(true)
        NyserdaPreTrainingFormApplications({data: {
            email: formData['email']['value'],
            fName: formData['fName']['value'],
            lName: formData['lName']['value'],
            // dateOfBirth:formData['dateOfBirth']['value'],
            cellPhone: formData['cellPhone']['value'],
            homePhone:formData['homePhone']['value'],
            course:formData['course']['value'],
            // dateOfCourse:formData['dateOfCourse']['value'],
            // TrainingOrganization:formData['TrainingOrganization']['value'],
            // TrainerName:formData['TrainerName']['value'],

            employmentMangement: formData['employmentMangement']['value'],
            educationLevel: formData['educationLevel']['value'],
            reasonEnroll: formData['reasonEnroll']['value'].map(item => item).join(',') + formData['reasonEnroll']['otherValue'],
            // infoSourceOfJobOpp: (formData['infoSourceOfJobOpp']['value']).map(item => item).join(','),
            expectationFromCourse: (formData['expectationFromCourse']['value']).map(item => item).join(','),
            versionName: '2025',
            studentMoodleId: formData?.email?.studentMoodleId || '',
            
            currentSalaryRange:formData['currentSalaryRange']['value'],
            expectedSalaryRange:formData['expectedSalaryRange']['value'],

            idFile:formData['idFile']['value'],
            isEmployed: formData['isEmployed']['value'],
            isSelfEmployed: formData['isSelfEmployed']['value'],
            employedWorkingType: formData['employedWorkingType']['value'],
            employedWorkingIn: formData['employedWorkingIn']['value'],
            isStudiedCleanEnergy: formData['isStudiedCleanEnergy']['value'],
            IsWorking: formData['IsWorking']['value'],
            currentEmployer: formData['currentEmployer']['value'],
            companyJoiningDate: formData['companyJoiningDate']['value'],
            lookingEmployment: formData['lookingEmployment']['value'],
            lookingEmploymentDetails: formData['lookingEmploymentDetails']['value'],
            openToIntership: '',

            technologiesExperience: {...expander1, otherValue: formData.technologiesExperience.otherValue},
            buildingSectorExperience: {...expander2, otherValue: formData.buildingSectorExperience.otherValue},
            roleExperience: {...expander3, otherValue: formData.roleExperience.otherValue},
            interestTechnologies: formData['interestTechnologies']['value'].map(item => item).join(',') + formData['interestTechnologies']['otherValue'],
            interestBuildingSector: formData['interestBuildingSector']['value'].map(item => item).join(','),
            interestRole: formData['interestRole']['value'].map(item => item).join(','),

            participatedVocationalProgram: formData['participatedVocationalProgram']['value'],
            courselanguage: formData['courselanguage']['value'],

            companySize: formData['companySize']['value'],
            workCulture: formData['workCulture']['value'],
            workBenefit: formData['workBenefit']['value'],
            
            jobTitle: formData['jobTitle']['value'],
            jobType: (formData['jobType']['value']).map(item => item).join(','),
            workLocation: (formData['workLocation']['value']).map(item => item).join(','),
            workSector: (formData['workSector']['value']).map(item => item).join(','),

          
            agreeStudentAgreement: agreeWithAgreement,
            readStudentAgreement: agreementRead,
            agreementLink: fileDate?.fileLink || "",

            agreeMediaForm: mediaFeature === 'Yes',
            readMediaForm: agreementRead1,
            mediaFormLink: fileDate1?.fileLink || "",

            // New
            NYSERDA_priorityPopulations: finalNyserdaValues.NYSERDA_priorityPopulations || '',
            CEA_priorityPopulations: finalNyserdaValues.CEA_priorityPopulations || '',
            
            // New
            sex: formData['sex']['value'],

            // New
            pronoun: formData['pronoun']['value'],

            // New
            ethnicity: ethnicitiesDynamic[formData['ethnicity']['value']] ? ethnicitiesDynamic[formData['ethnicity']['value']] : formData['ethnicity']['value'],
          
            formLanguage: localStorage.getItem('newFormFromLanguage') || 'en',
            userData: userSystemInfo
        }}).then(res => {
          if (res !== null) {
            setShow(true)
            setModalName("Verify Your Email")
            setLoader(false)
            toast.success(<Translate>Application Submitted Successfully</Translate>)
            handleReloadForm()
          }
        }).catch(error => {
          console.log("error: ", error);
          setLoader(false)
          toast.error(<Translate>Something went wrong</Translate>, {position: 'top-right', autoClose: 2000, hideProgressBar: true})
        })
      }
    
    } else {
      toast.error(<Translate>{'Please Fill All The Required Fields'}</Translate>, {position: 'top-right', autoClose: 2000, hideProgressBar: true})
    }
  }

  // Prefil Form Data
  const prefillApplicationForm = (data) => {
    const userData = data.attributes
    console.log("prefillApplicationForm: ", userData);
    // console.log("questionOptions: ", questionOptions);

    setFormData({...formData, 
      fName: {...formData['fName'], 'value': userData.fName, dataSet: {...formData['fName']['dataSet'], isError: false, errorMessage: ''}},
      lName: {...formData['lName'], 'value': userData.lName, dataSet: {...formData['lName']['dataSet'], isError: false, errorMessage: ''}},
      dateOfBirth: {...formData['dateOfBirth'], 'value': userData.dateOfBirth, dataSet: {...formData['dateOfBirth']['dataSet'], isError: false, errorMessage: ''}},
      // cellPhone: {...formData['cellPhone'], 'value': userData.cellPhone, dataSet: {...formData['cellPhone']['dataSet'], isError: false, errorMessage: ''}},
      // homePhone: {...formData['homePhone'], 'value': userData.homePhone, dataSet: {...formData['homePhone']['dataSet'], isError: false, errorMessage: ''}},
      // course: {...formData['course'], 'value': userData.course, dataSet: {...formData['course']['dataSet'], isError: false, errorMessage: ''}},
      // dateOfCourse: {...formData['dateOfCourse'], 'value': userData.dateOfCourse, dataSet: {...formData['dateOfCourse']['dataSet'], isError: false, errorMessage: ''}},
      // TrainingOrganization: {...formData['TrainingOrganization'], 'value': userData.TrainingOrganization, dataSet: {...formData['TrainingOrganization']['dataSet'], isError: false, errorMessage: ''}},
      // TrainerName: {...formData['TrainerName'], 'value': userData.TrainerName, dataSet: {...formData['TrainerName']['dataSet'], isError: false, errorMessage: ''}},
      // isEmployed: {...formData['isEmployed'], 'value': userData.isEmployed ? userData.isEmployed : '', dataSet: {...formData['isEmployed']['dataSet'], isError: false, errorMessage: ''}},
      // isSelfEmployed: {...formData['isSelfEmployed'], 'value': userData.isSelfEmployed ? userData.isSelfEmployed : '', dataSet: {...formData['isSelfEmployed']['dataSet'], isError: false, errorMessage: ''}, isVisible: formData['isEmployed']['value'].length > 0 && formData['isEmployed']['value'] !== "Yes" ? 'Yes' : 'No'},
      // employedWorkingType: {...formData['employedWorkingType'], 'value': userData.employedWorkingType, dataSet: {...formData['employedWorkingType']['dataSet'], isError: false, errorMessage: ''}, isVisible: formData['isEmployed']['value'].length > 0 && formData['isEmployed']['value'] !== "Yes" ? 'Yes' : 'No'},
      // employedWorkingIn: {...formData['employedWorkingIn'], 'value': userData.employedWorkingIn, dataSet: {...formData['employedWorkingIn']['dataSet'], isError: false, errorMessage: ''}, isVisible: formData['isEmployed']['value'].length > 0 && formData['isEmployed']['value'] !== "Yes" ? 'Yes' : 'No'},
      // currentEmployer: {...formData['currentEmployer'], 'value': userData.currentEmployer, dataSet: {...formData['currentEmployer']['dataSet'], isError: false, errorMessage: ''}, isVisible: formData['isEmployed']['value'].length > 0 && formData['isEmployed']['value'] !== "Yes" ? 'Yes' : 'No'},
      // isStudiedCleanEnergy: {...formData['isStudiedCleanEnergy'], 'value': userData.isStudiedCleanEnergy, dataSet: {...formData['isStudiedCleanEnergy']['dataSet'], isError: false, errorMessage: ''}},
      // IsWorking: {...formData['IsWorking'], 'value': userData.IsWorking, dataSet: {...formData['IsWorking']['dataSet'], isError: false, errorMessage: ''}},
      // companyJoiningDate: {...formData['companyJoiningDate'], 'value': userData.companyJoiningDate, dataSet: {...formData['companyJoiningDate']['dataSet'], isError: false, errorMessage: ''}},
      // employmentMangement: {...formData['employmentMangement'], 'value': userData.employmentMangement, dataSet: {...formData['employmentMangement']['dataSet'], isError: false, errorMessage: ''}},
      // educationLevel: {...formData['educationLevel'], 'value': userData.educationLevel, dataSet: {...formData['educationLevel']['dataSet'], isError: false, errorMessage: ''}},
      // reasonEnroll: {...formData['reasonEnroll'], 'value': userData['reasonEnroll'] ? (userData['reasonEnroll']).split(',').map((item) => (item).trim()) : [], dataSet: {...formData['reasonEnroll']['dataSet'], isError: false, errorMessage: ''}},
      // // awareNYSDeptLCC: {...formData['awareNYSDeptLCC'], 'value': userData.awareNYSDeptLCC, dataSet: {...formData['awareNYSDeptLCC']['dataSet'], isError: false, errorMessage: ''}},
      // // registeredNYSDeptLCC: {...formData['registeredNYSDeptLCC'], 'value': userData.registeredNYSDeptLCC, dataSet: {...formData['registeredNYSDeptLCC']['dataSet'], isError: false, errorMessage: ''}},
      // infoSourceOfJobOpp: {...formData['infoSourceOfJobOpp'], 'value': userData['infoSourceOfJobOpp'] ? (userData['infoSourceOfJobOpp']).split(',').map((item) => (item).trim()) : [], dataSet: {...formData['infoSourceOfJobOpp']['dataSet'], isError: false, errorMessage: ''}},
      // expectationFromCourse: {...formData['expectationFromCourse'], 'value': userData['expectationFromCourse'] ? (userData['expectationFromCourse']).split(',').map((item) => (item).trim()) : [], dataSet: {...formData['expectationFromCourse']['dataSet'], isError: false, errorMessage: ''}},
      // // paymentBy: {...formData['paymentBy'], 'value': userData.paymentBy, dataSet: {...formData['paymentBy']['dataSet'], isError: false, errorMessage: ''}},
      // currentSalaryRange: {...formData['currentSalaryRange'], 'value': userData.currentSalaryRange, dataSet: {...formData['currentSalaryRange']['dataSet'], isError: false, errorMessage: ''}},
      // // experienceInsideCleanEnergy: {...formData['experienceInsideCleanEnergy'], 'value': userData.experienceInsideCleanEnergy, dataSet: {...formData['experienceInsideCleanEnergy']['dataSet'], isError: false, errorMessage: ''}},
      // // experienceOutsideCleanEnergy: {...formData['experienceOutsideCleanEnergy'], 'value': userData.experienceOutsideCleanEnergy, dataSet: {...formData['experienceOutsideCleanEnergy']['dataSet'], isError: false, errorMessage: ''}},
      // lookingEmployment: {...formData['lookingEmployment'], 'value': userData.lookingEmployment, dataSet: {...formData['lookingEmployment']['dataSet'], isError: false, errorMessage: ''}},
      // lookingEmploymentDetails: {...formData['lookingEmploymentDetails'], 'value': userData.lookingEmploymentDetails, dataSet: {...formData['lookingEmploymentDetails']['dataSet'], isError: false, errorMessage: ''}, isVisible: formData['lookingEmployment']['value'].length > 0},
      // openToIntership: {...formData['openToIntership'], 'value': userData.openToIntership, dataSet: {...formData['openToIntership']['dataSet'], isError: false, errorMessage: ''}, isVisible: formData['lookingEmployment']['value'].length > 0},
      // // careerSupport: {...formData['careerSupport'], 'value': userData.careerSupport, dataSet: {...formData['careerSupport']['dataSet'], isError: false, errorMessage: ''}, isVisible: formData['lookingEmployment']['value'].length > 0},
      // electricalEfficiencyExperience: {...formData['electricalEfficiencyExperience'], 'value': userData['electricalEfficiencyExperience'] ? (userData['electricalEfficiencyExperience']).split(',').map((item) => (item).trim()) : [], dataSet: {...formData['electricalEfficiencyExperience']['dataSet'], isError: false, errorMessage: ''}},
      // thermalEfficiencyExperience: {...formData['mName'], 'value': userData['thermalEfficiencyExperience'] ? (userData['thermalEfficiencyExperience']).split(',').map((item) => (item).trim()) : [], dataSet: {...formData['thermalEfficiencyExperience']['dataSet'], isError: false, errorMessage: ''}},
      // electricalRenewableExperience: {...formData['electricalRenewableExperience'], 'value': userData['electricalRenewableExperience'] ? (userData['electricalRenewableExperience']).split(',').map((item) => (item).trim()) : [], dataSet: {...formData['electricalRenewableExperience']['dataSet'], isError: false, errorMessage: ''}},
      // thermalRenewableExperience: {...formData['thermalRenewableExperience'], 'value': userData['thermalRenewableExperience'] ? (userData['thermalRenewableExperience']).split(',').map((item) => (item).trim()) : [], dataSet: {...formData['thermalRenewableExperience']['dataSet'], isError: false, errorMessage: ''}},
      // interestedJobRoles: {...formData['interestedJobRoles'], 'value': userData['interestedJobRoles'] ? (userData['interestedJobRoles']).split(',').map((item) => (item).trim()) : [], dataSet: {...formData['interestedJobRoles']['dataSet'], isError: false, errorMessage: ''}},
      // participatedVocationalProgram: {...formData['participatedVocationalProgram'], 'value': userData.participatedVocationalProgram, dataSet: {...formData['participatedVocationalProgram']['dataSet'], isError: false, errorMessage: ''}},
      // courselanguage: {...formData['courselanguage'], 'value': userData.courselanguage, dataSet: {...formData['courselanguage']['dataSet'], isError: false, errorMessage: ''}},
      // idFile: {...formData['idFile'], 'value': userData.idFile, dataSet: {...formData['idFile']['dataSet'], isError: false, errorMessage: ''}},
    })
  }

  const getContent = (questionObject, singleOrMulti) => {
    // console.log("questionObject: ", questionObject.type);

    switch(questionObject.type) {

      case "simpleRadio":  return (
        <SimpleRadio className={singleOrMulti} item={questionObject['dataSet']} btnDisabled={btnDisabled} completeQuestionData={questionObject} validateData={validateData}/>
      )

      case "simpleCheckbox":  return(
        <Checkbox className={singleOrMulti} item={questionObject['dataSet']} btnDisabled={btnDisabled} completeQuestionData={questionObject} validateData={validateData}/>
      )
      
      case "simpleInput":  return(
        <Input className={singleOrMulti} setCoursesList={setCoursesList} setUserExistsData={setUserExistsData} item={questionObject} btnDisabled={btnDisabled} formType="preNysedra" setBtnDisabled={setBtnDisabled} prefillApplicationForm={prefillApplicationForm}  completeQuestionData={questionObject} formData={formData} validateData={validateData} setFormData={setFormData}/>
      )
      
      case "radioWithOther":  return (
          <SimpleRadioWithOther className={singleOrMulti} item={questionObject} btnDisabled={btnDisabled} completeQuestionData={questionObject} validateData={validateData}/>
        )

      case "checkboxWithOther":  return (
          <SimpleCheckboxWithOther className={singleOrMulti} item={questionObject} btnDisabled={btnDisabled} completeQuestionData={questionObject} validateData={validateData}/>
        )
      
      case "upload":  return (
          <Upload className={singleOrMulti} file={formData.idFile.value} setLoader={setLoader} item={questionObject} email={formData.email.value} btnDisabled={btnDisabled} completeQuestionData={questionObject} validateData={validateData}/>
        )

      case "groupedradio":return(
        <GroupedRadio className={singleOrMulti} item={questionObject} completeQuestionData={questionObject} btnDisabled={btnDisabled} validateData={validateData}/>
      )

      case "singlelineRadio":return(
        <SingleLineRadio className={singleOrMulti} item={questionObject} completeQuestionData={questionObject} btnDisabled={btnDisabled} validateData={validateData}/>
      )

      case "dropdown": return (
        <Dropdown className={singleOrMulti} coursesList={coursesList} item={questionObject} completeQuestionData={questionObject} btnDisabled={btnDisabled} validateData={validateData}/>
      )

      case "dob":  return (
        <DOB className={singleOrMulti} item={questionObject}  btnDisabled={btnDisabled} completeQuestionData={questionObject} validateData={validateData}/>
        // <Input item={questionObject} completeQuestionData={questionObject} validateData={validateData}/>
      )
        
      case "simpleRadioWithOther":return(
        <SimpleRadioWithOther className={singleOrMulti} coursesList={coursesList} item={questionObject} completeQuestionData={questionObject} btnDisabled={btnDisabled} validateData={validateData}/>
      )

      case "checkboxWithExpander":return(
        <CheckboxWithExpander className={singleOrMulti} formType={"pre-nyserda"} coursesList={coursesList} item={questionObject} completeQuestionData={questionObject} btnDisabled={btnDisabled} validateData={validateData}/>
      )
    }

  }
      
  return (
    <div className='card'>
        <div className='formbg'>
        <span className='formheading'><Translate>TRAINING PRE SURVEY</Translate></span><br /><br />
        <div className='single-elements-container'>
            { 
              Object.keys(formData).map((eachQuestion, i) => {
              if (i < 6) {
                  return (
                  <div>
                      {getContent(formData[eachQuestion], 'single-element')}
                  </div>
                )
              }
              })
            }
        </div>
        {
          Object.keys(formData).map((eachQuestion, i) => {
            if (i >= 6) {
                return (
                <div>
                  {getContent(formData[eachQuestion])}
                </div>
              )
            }
          })
        }
           {/* <div className='single-elements-container'> */}
           {checked ? <div className='single-group-child'>
              <div className='checkboxlabel'>
                <input type="checkbox" onChange={(event) => setAgreeWithAgreement(event.target.checked)}></input>
                <strong className={`strongclass`}>I agree to the CEA Student Participation Agreement policy <span style={{color: 'blue', fontSize: '16px', cursor: 'pointer'}} onClick={() => {setShow1(true); setAgreementRead(true); setModalName1('Agreement File')}}>(Read the latest version here)</span><span className='mandatory' style={{color: 'red'}}>*</span></strong>
              </div>
            </div> : null
           }

          {checked1 ? <div className='single-group-child'>
              <strong className={`question-element`}><Translate>I agree to the CEA Media Release Form <span style={{color: 'blue', fontSize: '16px', cursor: 'pointer'}} onClick={() => {setShow1(true); setAgreementRead1(true); setModalName1('Media File')}}>(Read the latest version here)</span></Translate>{<span className='required'>*</span>}</strong>
              <div className='checkboxlabel'>
                <input id='media' checked name='media' value={'Yes'} type="radio" onChange={(event) => setMediaFeature(event.target.value)}></input>
                <strong className={`strongclass`}>Yes</strong>
              </div>
              <div className='checkboxlabel'>
                <input id='media' name='media' value={'No'} type="radio" onChange={(event) => setMediaFeature(event.target.value)}></input>
                <strong className={`strongclass`}>No</strong>
              </div>
            </div> : null
           }
           {/* </div> */}
            <div className='single-submit-button'>
              <FormButton text="Submit" handleSubmit={() => handleSubmit()} loading={loader}/>
            </div>
        </div>
        <Modal isOpen={show} onClosed={() => handleCancel()} className="modal-dialog-centered">
          <ModalHeader><Translate>{modalName}</Translate></ModalHeader>
          <ModalBody>
             <div>
                <div className='d-flex flex-column justify-evenly align-items-center'>
                  <img src={FormSubmitted} style={{height: '100px', width: '100px'}}></img>
                  <p className='text-center fs-3'><Translate>Thank You!! Your response has been submitted</Translate></p>
                </div>
              </div>
          </ModalBody>
      </Modal>

      <Modal isOpen={show1} toggle={() => setShow1(!show1)} className='modal-dialog-centered modal-lg'>
        <ModalHeader className='bg-transparent' toggle={() => setShow1(!show1)}></ModalHeader>
        <ModalBody className='px-sm-5 pt-50 pb-5'>
          {/* <BlobProvider document={agreementUrl}> */}
            <Document file={{url: modalName1 === "Media File" ? fileDate1?.fileLink : fileDate?.fileLink || ''}} onLoadSuccess={onDocumentLoadSuccess}>
              {Array.from(new Array(numPages), (el, index) => (
                <Page key={`page_${index + 1}`} pageNumber={index + 1} renderTextLayer={false} renderAnnotationLayer={false}/>
              ))}
            </Document>
          {/* </BlobProvider> */}
        </ModalBody>
      </Modal>
    </div>
  )
}

export default PretrainingV3
