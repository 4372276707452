import React, { useEffect, useState } from 'react'
import Paragraph from '../../../Inputs/Paragraph'
import Checkbox from '../../../Inputs/Checkbox'
import FormButton from '../../../Components/FormButton'
import '../../../Inputs.css'
import SimpleRadio from '../../../Components/Radio/SimpleRadio'
import { toast } from 'react-toastify';
import { GetCertificates, getCoursePreferences, getCoursesByPriority, getReferrals } from '../../../Apis/courses'
import Input from '../../../Inputs/Input'
import CreatableSelect from 'react-select/creatable'
import { StandaloneSearchBox } from "@react-google-maps/api";
import { addStudentsApplication, checkEmailVerify, checkUserEmail, getApplicationsVersions, getStates } from '../../../Apis/studentApplication'
import { Badge, Modal, ModalBody, ModalHeader } from "reactstrap";
import GoogleMapAddress from '../../../Components/Map/map'
import SimpleRadioWithOther from '../../../Components/Radio/SimpleRadioWithOther/simpleRadioWithOther'
import SimpleCheckboxWithOther from '../../../Components/Checkbox/SimpleChcekBoxWithOther/simpleCheckboxWithOther'
import FormSubmitted from './../../../assets/submitted.png'
import Upload from '../../../Components/Upload/upload'
import moment from 'moment/moment'
import MapPin from 'feather-icons-react/build/IconComponents/MapPin' 
import {Colors} from '../../../utils/colors'
import DOB from '../../../Inputs/Dob'
import RadioHavingOptionChildren from '../../../Components/Radio/RadioHavingOptionChildrens'
import { Translate } from 'react-auto-translate'
import ReactGA from 'react-ga4'
import getIp from 'react-public-ip';
import { UAParser } from 'ua-parser-js'
import { loadModules } from "esri-loader"
import { NUMBER_REGEX } from '../../../utils/constants'
// import { hotjar } from 'react-hotjar';


const StudentApplicationV3 = () => {
  // ** Form Data

  const [certificationsList, setCertificationsList] = useState([])
  const [referralList, setReferralList] = useState([])
  const [coursePreferences, setCoursePreferences] = useState([]);
  const [interestedCoursePreferences, setInterestCoursePreferences] = useState([]);
  const [states, setStates] = useState([]);
  const [show, setShow] = useState(false)
  const [btnDisabled, setBtnDisabled] = useState(true)
  const [Lat, setLat] = useState('')
  const [Lng, setLng] = useState('')
  const [version, setVersion] = useState('');
  const [modalName, setModalName] = useState('')
  const [cancelClicked, setCancelClicked] = useState(false)
  const [userExistsData , setUserExistsData] = useState({})

  const [loader, setLoader] = useState(false)

  // ** Check is Disadvantage Communities
  const [IsDisadvantage, setIsDisadvantage] = useState('no')

  // ** Verify Email
  const [resendEmailBtnClicked, setResendEmailBtnClicked] = useState(false)
  const [OTP, setOTP] = useState("");
  const [userEmailVerified, setUserEmailVerified] = useState(false)

   // Make Options For Interested Course
   const interestedCoursePreferencesOptions = []
   interestedCoursePreferences.forEach(option => {
     interestedCoursePreferencesOptions.push(option['line1'])
   })
 
  //  console.log('interestedCoursePreferencesOptions: ', interestedCoursePreferencesOptions)

   // Make Options For Who Referred You?
   const refereralOptions = []
   referralList.forEach(option => {
     refereralOptions.push(option['name'])
   })
   
  //  console.log("refereralOptions: ", refereralOptions);
   
   // Make Options For Course Interested?
   const interestedCoursesOptions = []
   coursePreferences.forEach(option => {
    interestedCoursesOptions.push(option)
   })

  //  Certifications List
  const highestEducationCertificates = [
    {"label": "Energy Management", "value": "Energy Management", "isFixed": true},
    {"label": "Electric Engineering", "value": "Electric Engineering", "isFixed": true},
    {"label": "Gas Engineering", "value": "Gas Engineering", "isFixed": true},
    {"label": "Architecture", "value": "Architecture", "isFixed": true},
    {"label": "Business", "value": "Business", "isFixed": true},
    {"label": "Energy", "value": "Energy", "isFixed": true},
    {"label": "Engineering", "value": "Engineering", "isFixed": true},
    {"label": "Mechanical Engineering", "value": "Mechanical Engineering", "isFixed": true},
    {"label": "Chemical Engineering", "value": "Chemical Engineering", "isFixed": true},
    {"label": "Health", "value": "Health", "isFixed": true},
    {"label": "Leadership", "value": "Leadership", "isFixed": true},
    {"label": "Environmental Science", "value": "Environmental Science", "isFixed": true},
    {"label": "Communications", "value": "Communications", "isFixed": true},
    {"label": "Journalism", "value": "Journalism", "isFixed": true},
    {"label": "Finance", "value": "Finance", "isFixed": true},
    {"label": "Urban Planning", "value": "Urban Planning", "isFixed": true},
    {"label": "Anthropology", "value": "Anthropology", "isFixed": true},
    {"label": "Literature", "value": "Literature", "isFixed": true},
    {"label": "Administration", "value": "Administration", "isFixed": true},
    {"label": "Arts", "value": "Arts", "isFixed": true},
    {"label": "Sustainability", "value": "Sustainability", "isFixed": true},
    {"label": "History", "value": "History", "isFixed": true},
    {"label": "Political Science", "value": "Political Science", "isFixed": true},
    {"label": "Criminal Justice", "value": "Criminal Justice", "isFixed": true},
    {"label": "Environmental Technology", "value": "Environmental Technology", "isFixed": true},
    {"label": "Film", "value": "Film", "isFixed": true},
    {"label": "International Relations", "value": "International Relations", "isFixed": true},
    {"label": "Oil Engineering", "value": "Oil Engineering", "isFixed": true},
    {"label": "Accounting", "value": "Accounting", "isFixed": true},
    {"label": "Marketing", "value": "Marketing", "isFixed": true},
    {"label": "Social Work", "value": "Social Work", "isFixed": true},
    {"label": "Community Organizing", "value": "Community Organizing", "isFixed": true},
    {"label": "Digital Media", "value": "Digital Media", "isFixed": true},
    {"label": "Computer Engineering", "value": "Computer Engineering", "isFixed": true},
    {"label": "Sustainable Communities", "value": "Sustainable Communities", "isFixed": true},
    {"label": "Building Maintenance and Management", "value": "Building Maintenance and Management", "isFixed": true},
    {"label": "Mineral Engineering", "value": "Mineral Engineering", "isFixed": true},
    {"label": "Renewable Energy", "value": "Renewable Energy", "isFixed": true},
    {"label": "Network Cabling", "value": "Network Cabling", "isFixed": true},
    {"label": "Geology", "value": "Geology", "isFixed": true},
    {"label": "Information Technology", "value": "Information Technology", "isFixed": true},
    {"label": "Health Administration", "value": "Health Administration", "isFixed": true},
    {"label": "Management", "value": "Management", "isFixed": true},
    {"label": "Economics", "value": "Economics", "isFixed": true},
    {"label": "Business Analytics", "value": "Business Analytics", "isFixed": true}
  ]  

  const NYSERDA_priorityPopulationsDynamic = {
    'Veterans': 'Veterans',
    'Native Americans': 'Native Americans',
    'Individuals with disabilities': 'Disabled',
    'Immigrant': 'Immigrant',
    'Refugee': 'Refugee',
    'English as a second language (ESL)': 'ESL',
    '1st Generation College': '1st Generation College',
    '2nd Generation College': '2nd Generation College',
    'Low income individuals - (60% of NYS median income or receiving public assistance)': 'Low Income',
    'Unemployed plant workers': 'Unemployed plant workers',
    'Previously incarcerated Individuals': 'Previously incarcerated',
    'Homeless Individuals': 'Homeless',
    '16 to 24 year olds in work preparedness training programs that include energy related technical Training': '16-24 years olds in WFD program',
    'Single parents': 'Single parents',
    'None of the above': 'None of the above'
  }

  const ethnicitiesDynamic = {
    'American Indian or Alaska Native (Eg: Navajo nation, Blackfeet tribe, Mayan, Aztec, Native Village or Barrow Inupiat Traditional Government, Nome Eskimo Community, etc)': 'American Indian or Alaska Native',
    'Asian (Eg: Chinese, Filipino, Asian Indian, Vietnamese, Korean, Japanese, etc)': 'Asian',
    'Black or African American (Eg: African American, Jamaican, Haitian, Nigerian, Ethiopian, Somalian, etc)': 'Black or African American',
    'Hispanic, Latino or Spanish (Eg: Mexican or Mexican American, Puerto Rican, Cuban, Salvadoran, Dominican, Colombian, etc)': 'Hispanic, Latino or Spanish',
    'Middle Eastern or North African (Eg: Lebanese, Iranian, Egyptian, Syrian, Moroccan, Algerian, etc)': 'Middle Eastern or North African',
    'Native Hawaiian or Other Pacific Islander (Eg: Native Hawaiian, Samoan, Chamorro, Tongan, Fijian, etc)': 'Native Hawaiian or Other Pacific Islander',
    'White (Eg: German, Irish, English, Italian, Polish, French, etc)': 'White'
  }

  // city, date, versionName
  const [formData, setFormData] = useState(
     { 
         email: {value: '', dataSet: {question: "Email", isRequired: true, formDataKey: 'email', isError: false, errorMessage: ''}, type: 'simpleInput', inputType: 'text', isEmail: true, userVerified: false, notifyMe: true, emailCommunication: true}, 
         
         fName: {value: '', dataSet: {question: "First Name", isRequired: true, formDataKey: 'fName', isError: false, errorMessage: ''}, type: 'simpleInput', inputType: 'text', disable: true}, 

         mName: {value: '', dataSet: {question: "Last Name", isRequired: true, formDataKey: 'mName', isError: false, errorMessage: ''}, type: 'simpleInput', inputType: 'text', disable: true},

         phoneNumber: {value: '', dataSet: {question: "Primary Phone Number", isRequired: true, formDataKey: 'phoneNumber', isError: false, errorMessage: ''}, type: 'simpleInput', inputType: 'number', disable: true, notifyMe: true, smsCommunication: true},

         address: {value: '', dataSet: {question: "Address", isRequired: true, formDataKey: 'address', isError: false, errorMessage: ''}, type: 'simpleInputWithMap', inputType: 'text', disable: true}, 
         
         state: {value: '', dataSet: {question: "State", isRequired: true, formDataKey: 'state', isError: false, errorMessage: ''}, type: 'dropdown', inputType: 'text', disable: true},
         
         city: {value: '', dataSet: {question: "City", isRequired: true, formDataKey: 'cuty', isError: false, errorMessage: ''}, type: 'simpleInput', inputType: 'text', disable: true},

         zipcode: {value: '', dataSet: {question: "Zip Code", isRequired: true, formDataKey: 'zipcode', isError: false, errorMessage: ''}, type: 'simpleInput', inputType: 'number', disable: true},

         courceInterested: {value: '', dataSet: {question: "Please indicate which course you are most interested in attending. (Select the one that most applies to you)", options: coursePreferences, isRequired: true, groupName: 'gender', formDataKey: 'courceInterested', isError: false, errorMessage: ''}, type: 'simpleRadio', isChildren: true, disable: true}, 
 
         additionalCourse: {value: [], dataSet: {question: "Please indicate any additional courses you may be interest in taking? (Select the one(s) that most applies to you)", options: interestedCoursePreferencesOptions, isRequired: true, groupName: 'additionalCourse', formDataKey: 'additionalCourse', isError: false, errorMessage: ''}, type: 'simpleCheckbox', disable: true}, 
         
         isEighteen: {value: '', dataSet: {question: "Are you currently at least 18 years old?", options: ["Yes", "No"], isRequired: true, groupName: 'isEighteen', formDataKey: 'isEighteen', isError: false, errorMessage: ''},type: 'simpleRadio', disable: true}, 
         
         linkedin: {value: '', dataSet: {question: "What's your LinkedIn profile URL? (optional)", isRequired: false, formDataKey: 'linkedin', isError: false, errorMessage: ''}, type: 'simpleInput', inputType: 'text', socialLink: true, disable: true},
        
         currentEmploymentStatus : {value: '', dataSet: {question: "Select which best describes your employment status", options: [{value: "Employed (Full Time)", showChildren: false, childrens: ["Employed full-time in clean energy", "Employed full-time not in clean energy"]}, {value: "Underemployed (Part Time)", showChildren: false, childrens: ["Employed part-time in clean energy", "Employed part-time not in clean energy"]}, {value: 'Self-employed', showChildren: false, childrens: ["Self-Employed Full Time in clean energy", "Self-Employed Full Time not in clean energy", "Self-Employed Part Time in clean energy", "Self-Employed Part Time not in clean energy"]}, {value: 'Unemployed', showChildren: false, childrens: []}], isRequired: true, groupName: 'currentEmploymentStatus', formDataKey: 'currentEmploymentStatus', isError: false, errorMessage: ''},type: 'radioWithExpander', disable: true},          

         //  New
         workExperience: {value: '', dataSet: {question: "How many total years of working experience do you have?", isRequired: true, formDataKey: 'workExperience', isError: false, errorMessage: ''}, type: 'simpleInput', inputType: 'number', disable: true},
         
         //  New
         workExperienceInCE: {value: '', dataSet: {question: "How many of those are in the clean energy industry?", isRequired: true, formDataKey: 'workExperienceInCE', isError: false, errorMessage: ''}, type: 'simpleInput', inputType: 'number', disable: true},
         
         seekingEmployment: {value: '', dataSet: {question: "Are you currently actively looking for employment opportunity?", options: ["Yes", "No"], isRequired: true, groupName: 'seekingEmployment', formDataKey: 'seekingEmployment', isError: false, errorMessage: ''},type: 'simpleRadio', disable: true}, 
         
         optedPlacementSupport: {value: '', dataSet: {question: "If seeking employment, would you be interested in receiving career services and/or job placement support?", options: ["Yes", "No"], isRequired: true, groupName: 'optedPlacementSupport', formDataKey: 'optedPlacementSupport', isError: false, errorMessage: ''}, type: 'simpleRadio', isChildren: false, disable: true, isVisible: false}, 

         pastExperienceAreas: {value: [], dataSet: {question: "Do you have any past experience working with any of the following technologies?", options: ["Lighting Systems", "HVAC Systems", "Heating Systems", "Hot Water Systems", "Building Envelope", "Wind", "Solar PV", "Solar Thermal", "Geothermal", "None of the above", "Other"], isRequired: true, groupName: 'pastExperienceAreas', formDataKey: 'pastExperienceAreas', isError: false, errorMessage: ''}, type: 'checkboxWithOther', disable: true}, 

         highestEducation: {value: '', dataSet: {question: "What is your highest level of completed education?", options: ["Did not complete High School", "High School/HSE/GED", "Associates Degree", "Bachelors Degree", "Graduate Degree", "Post Graduate"], isRequired: true, groupName: 'highestEducation', formDataKey: 'highestEducation', isError: false, errorMessage: ''}, type: 'simpleRadio', disable: true}, 
         
         
         
         studyArea: {value: '', dataSet: {question: "Area of study (Engineering, Literature, etc)", isRequired: true, formDataKey: 'studyArea', isError: false, errorMessage: ''}, type: 'dropdown-studyArea', inputType: 'text', disable: true, isVisible: false},
         
         recentlyGraduated: {value: '', dataSet: {question: "Are you currently a student or a recent graduate (in the last 12 months) from a degree program at a college or university?", options: ["Yes", "No"], isRequired: true, groupName: 'recentlyGraduated', formDataKey: 'recentlyGraduated', isError: false, errorMessage: ''},type: 'simpleRadio', disable: true}, 
         
         certifications: {value: [], otherValue: '', dataSet: {question: "Do you have any green industry certifications?", options: certificationsList, isRequired: true, groupName: 'certifications', formDataKey: 'certifications', isError: false, errorMessage: ''}, type: 'checkboxWithOther', disable: true}, 
         
         validDriverLicense: {value: '', dataSet: {question: "Do you have a valid driver license?", options: ["Yes, standard license (Class D)", "Yes, CDL license (Class A, B or C)", "Both", "No"], isRequired: true, groupName: 'validDriverLicense', formDataKey: 'validDriverLicense', isError: false, errorMessage: ''}, type: 'simpleRadio', disable: true}, 

         carAccess: {value: '', dataSet: {question: "Do you have regular access to a car?", options: ["Yes", "No"], isRequired: true, groupName: 'carAccess', formDataKey: 'carAccess', isError: false, errorMessage: ''}, type: 'simpleRadio', disable: true, isVisible: false}, 

         sourceApplication: {value: '', extraDescription: '', dataSet: {question: "How did you hear about the Clean Energy Academy?", options: referralList, isRequired: true, groupName: 'sourceApplication', formDataKey: 'sourceApplication', isError: false, errorMessage: ''},type: 'simpleRadio', disable: true}, 

        //  // Removed
        //  NYSERDA_priorityPopulations: {value: [], dataSet: {question: "Which of these do you identify with? (Select all that apply to you)", options: ["Veterans", "Native Americans", "Individuals with disabilities", "Immigrant", "Refugee", "English as a second language (ESL)", "1st Generation College", "2nd Generation College", "Low income individuals - (60% of NYS median income or receiving public assistance)", "Unemployed plant workers", "Previously incarcerated Individuals", "Homeless Individuals", "16 to 24 year olds in work preparedness training programs that include energy related technical Training", "Single parents", "None of the above"], isRequired: true, groupName: 'NYSERDA_priorityPopulations', formDataKey: 'NYSERDA_priorityPopulations', isError: false, errorMessage: ''}, type: 'simpleCheckbox', disable: true}, 
 
        //  // Removed
        //  sex: {value: '', city: '', stateShortcode: '', dataSet: {question: "Do you identify with any of the following?", options: ["Male", "Female", "Non-binary", "I prefer not to say"], isRequired: true, groupName: 'sex', formDataKey: 'sex', isError: false, errorMessage: ''},type: 'simpleRadio', disable: true}, 
         
        //  // Removed
        //  pronoun: {value: '', dataSet: {question: "How would you like to be addressed (your pronouns)?", options: ["He/Him", "She/Her", "They/Them", "Other"], isRequired: false, groupName: 'pronoun', formDataKey: 'pronoun', isError: false, errorMessage: ''},type: 'radioWithOther', disable: true}, 

        //  // Removed
        //  ethnicity: {value: '', dataSet: {question: "Which of the following ethnicities do you most closely identify with?", options: ["American Indian or Alaska Native (Eg: Navajo nation, Blackfeet tribe, Mayan, Aztec, Native Village or Barrow Inupiat Traditional Government, Nome Eskimo Community, etc)", "Asian (Eg: Chinese, Filipino, Asian Indian, Vietnamese, Korean, Japanese, etc)", "Black or African American (Eg: African American, Jamaican, Haitian, Nigerian, Ethiopian, Somalian, etc)", "Hispanic, Latino or Spanish (Eg: Mexican or Mexican American, Puerto Rican, Cuban, Salvadoran, Dominican, Colombian, etc)", "Middle Eastern or North African (Eg: Lebanese, Iranian, Egyptian, Syrian, Moroccan, Algerian, etc)", "Native Hawaiian or Other Pacific Islander (Eg: Native Hawaiian, Samoan, Chamorro, Tongan, Fijian, etc)", "White (Eg: German, Irish, English, Italian, Polish, French, etc)", "I prefer not to say"], isRequired: true, groupName: 'ethnicity', formDataKey: 'ethnicity', isError: false, errorMessage: ''},type: 'simpleRadio', disable: true},
         
         referredBy: {value: '', dataSet: {question: "If you were referred by 'Other', who referred you?", isRequired: false, formDataKey: 'referredBy', isError: false, errorMessage: ''}, type: 'simpleInput', inputType: 'text', disable: true, isVisible: false},

         resume: {value: '', dataSet: {question: "If you have a resume, please upload it (NOTE: this is optional)", isRequired: false, formDataKey: 'resume', isError: false, errorMessage: '', allowedFileType: ['docx', 'pdf', 'doc']}, type: 'upload', inputType: 'text', note: 'this is optional', disable: true},
         
         emailVerified: false,
         phoneVerified: false
  })
 
  // User System Info
  const [userSystemInfo, setUserSystemInfo] = useState({info: "", ip: ""})

  useEffect(() => {
    document.title = "Student Application Form"
    ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: window.location.pathname });

    getStates().then(res => {
        if(res != null){
            const data = res.data
            const statesArray = []
            for(let i = 0; i < data.length; i++){
              let state = data[i].attributes.stateName;
              statesArray.push({label: state, value: state, isFixed: true})
            }
            setStates(statesArray);
        }else{
            
        }
    }).catch(error => {
      console.log("error: ", error);
    })

    getCoursesByPriority().then(res => {
        if (res !== null) {
          const responseData = res.data
          let coursePreferencesPriority = {}
          let interestedCoursePreferences = {}
          let referralPriority = {}
          for (let i = 0; i < responseData.length; i++) {
            if (responseData[i].attributes.type === 1) {
              coursePreferencesPriority = responseData[i].attributes.priorities
            } else if (responseData[i].attributes.type === 2) {
              interestedCoursePreferences = responseData[i].attributes.priorities
            } else if (responseData[i].attributes.type === 3) {
                referralPriority = responseData[i].attributes.priorities
            }
          }

          // Get courses list
          getCoursePreferences().then(res => {
            if (res !== null) {
              const coursePreferencesTable = []
              const interestedCoursePreferencesTable = []
              const data = res.data
              for (let i = 0; i < data.length; i++) {
                if (data[i].attributes.type === 1) {
                  coursePreferencesTable.push({
                      id: data[i].id,
                    line1: data[i].attributes.line1,
                    line2: data[i].attributes.line2,
                    line3: data[i].attributes.line3
                })
                } else {
                  interestedCoursePreferencesTable.push({
                    id: data[i].id,
                    line1: data[i].attributes.line1
                  })
                }
              }

              // set items in array according to priority

              let coursePreferencesPriorityTableData = []
              Object.keys(coursePreferencesPriority).forEach(key => {
                const priority = coursePreferencesPriority[key] // 29
                ////  console.log("key: ", priority);
                for (let i = 0; i < coursePreferencesTable.length; i++) {
                  if (coursePreferencesTable[i].id === priority) {
                    coursePreferencesPriorityTableData.push(coursePreferencesTable[i])
                  }
                }
              })

              let makeNewArray1 = coursePreferencesTable
              let notPresentPriorityArray1 = makeNewArray1.filter(item => {
                  if (coursePreferencesPriorityTableData.includes(item) === false) {
                    return item
                  }
              })

              for (let i = 0 ; i < notPresentPriorityArray1.length; i++) {
                coursePreferencesPriorityTableData.push(notPresentPriorityArray1[i])
              }

              //////////////////////////////// For Interested Course Preferences ////////////////////////////////

              const interestedCoursePreferencesPriorityTableData = []
              Object.keys(interestedCoursePreferences).forEach(key => {
                const priority = interestedCoursePreferences[key] // 29
                for (let i = 0; i < interestedCoursePreferencesTable.length; i++) {
                  if (interestedCoursePreferencesTable[i].id === priority) {
                    interestedCoursePreferencesPriorityTableData.push(interestedCoursePreferencesTable[i])
                  }
                }
              })

              let makeNewArray = interestedCoursePreferencesTable
              let notPresentPriorityArray = makeNewArray.filter(item => {
                  if (interestedCoursePreferencesPriorityTableData.includes(item) === false) {
                    return item
                  }
              })

              for (let i = 0 ; i < notPresentPriorityArray.length; i++) {
                interestedCoursePreferencesPriorityTableData.push(notPresentPriorityArray[i])
              }

            //  //  console.log("coursePreferencesPriorityTableData: ", interestedCoursePreferencesPriorityTableData);
              setCoursePreferences(coursePreferencesPriorityTableData)
              setInterestCoursePreferences(interestedCoursePreferencesPriorityTableData)
            } else {
              setCoursePreferences([])
              setInterestCoursePreferences([])
            }
          }).catch(() => {
            ////  console.log("error: ", error)
            setCoursePreferences([])
            setInterestCoursePreferences([])
            toast.error(<Translate>Something went wrong</Translate>)
          })

          getReferrals().then(res => {
            if (res !== null) {
              const data = res.data
              // Referral
              const ReferralPreferencesTable = []
              for (let i = 0; i < data.length; i++) {
                ReferralPreferencesTable.push({
                  name: `${data[i].attributes.name}`,
                  id: data[i].id
                })
              }

                // set items in array according to priority

                let referrralTableData = []
                Object.keys(referralPriority).forEach(key => {
                  const priority = referralPriority[key] // 29
                  ////  console.log("key: ", priority);
                  for (let i = 0; i < ReferralPreferencesTable.length; i++) {
                    if (ReferralPreferencesTable[i].id === priority) {
                      referrralTableData.push(ReferralPreferencesTable[i])
                    }
                  }
                })

                let makeNewArray1 = ReferralPreferencesTable
                let notPresentPriorityArray1 = makeNewArray1.filter(item => {
                    if (referrralTableData.includes(item) === false) {
                      return item
                    }
                })

                for (let i = 0 ; i < notPresentPriorityArray1.length; i++) {
                  referrralTableData.push(notPresentPriorityArray1[i])
                }
            //   console.log("ReferralPreferencesTable: ", ReferralPreferencesTable, referrralTableData)
            
            console.log("Sort Array of Objects Alphabetically");
            const Std_arr = referrralTableData
            const sortedList = Std_arr.sort((a, b) =>
              a.name.localeCompare(b.name));
              console.log(sortedList);
              console.log("sortedList.indexOf('other'): ", sortedList.indexOf('Other'));
              const newData = sortedList.filter(item => (item.name !== "Other" && item.name !== "Referral"))
              newData.push((sortedList.filter(item => item.name === "Referral"))[0])
              newData.push((sortedList.filter(item => item.name === "Other"))[0])
              setReferralList(newData)
            } else {
              setReferralList([])
              toast.error(<Translate>Something went wrong</Translate>)
            }
          }).catch(() => {
            setReferralList([])
            toast.error(<Translate>Something went wrong</Translate>)
          })
        } else {
          toast.error(<Translate>Something went wrong</Translate>)
          setCoursePreferences([])
          setInterestCoursePreferences([])
        }
    }).catch(error => {
      console.log("error: ", error)
      toast.error(<Translate>Something went wrong</Translate>)
      setCoursePreferences([])
      setInterestCoursePreferences([])
    })

    GetCertificates().then(res => {
      if (res !== null) {
        const data = res.data
        // Referral
        const certificationsTable = []
        for (let i = 0; i < data.length; i++) {
          if (data[i].attributes.active) {
            certificationsTable.push(
              `${data[i].attributes.name}`
            )
          }
        }
        console.log("");
        setCertificationsList(certificationsTable)
      } else {
        setCertificationsList([])
        toast.error(<Translate>Something went wrong</Translate>)
      }
    }).catch(() => {
      setCertificationsList([])
      toast.error(<Translate>Something went wrong</Translate>)
    })

    getApplicationsVersions('studentapplication').then(res => {
      if(res !== null){
          const selectApplicationsArray = res.data[0]
          let vName = selectApplicationsArray.attributes.versionName
          setVersion(vName)
         //  console.log("selectApplicationsArray: ", vName)
      }else {

      }
    }).catch(error => {
        toast.error(<Translate>Something went wrong</Translate>, {position: 'top-right', autoClose: 3000})
      //  console.log("error: ", error);
    })

    async function getIPAddress() {
      const ipv4 = await getIp.v4() || "";
      const parser = new UAParser();
      // console.log("ipv4: ", ipv4);
      setUserSystemInfo({...userSystemInfo, info: parser.getResult(), ip: ipv4})
    }
    getIPAddress()
  }, [])
  useEffect(() => {
    if (interestedCoursePreferencesOptions.length > 0 && formData.additionalCourse.dataSet.options.length === 0)  {
        setFormData({...formData, additionalCourse: {...formData['additionalCourse'], ['dataSet']: {...formData['additionalCourse']['dataSet'], 'options': interestedCoursePreferencesOptions}}})
    }
  }, [interestedCoursePreferencesOptions])

  useEffect(() => {
    if (refereralOptions.length > 0 && formData.sourceApplication.dataSet.options.length === 0)  {
        setFormData({...formData, sourceApplication: {...formData['sourceApplication'], ['dataSet']: {...formData['sourceApplication']['dataSet'], 'options': refereralOptions}}})
      }
  }, [refereralOptions])

  useEffect(() => {
    if (interestedCoursesOptions.length > 0 && formData.courceInterested.dataSet.options.length === 0)  {
        setFormData({...formData, courceInterested: {...formData['courceInterested'], ['dataSet']: {...formData['courceInterested']['dataSet'], 'options': interestedCoursesOptions}}})
      }
  }, [interestedCoursesOptions])

  useEffect(() => {
    if (certificationsList.length > 0 && formData.certifications.dataSet.options.length === 0)  {
        setFormData({...formData, certifications: {...formData['certifications'], ['dataSet']: {...formData['certifications']['dataSet'], 'options': certificationsList}}})
      }
  }, [certificationsList])
  
  // console.log("formData: ", formData);

  const [IsLoading, setIsLoading] = useState(false)
   
  const validateData = () => {
    let functionReturn = true
    let validateFormData = formData
    
    Object.keys(validateFormData).forEach(eachQuestion => {
      // Checking Required ------------------------------------------------------------------------------------------------------------------
      if (eachQuestion !== 'emailVerified' && eachQuestion !== "phoneVerified") {
        const isNumberError = (validateFormData[eachQuestion]['inputType'] === "number" && !(NUMBER_REGEX.test((validateFormData[eachQuestion]?.['value'])))) || ((((validateFormData[eachQuestion]?.['value'] > 100) || (validateFormData[eachQuestion]?.['value'] < 0)) && (eachQuestion === "workExperience" || eachQuestion === "workExperienceInCE")))
        if (validateFormData[eachQuestion]?.['value'] !== null) {
          if (eachQuestion === "phoneNumber") {
            console.log('value 1:--------', isNumberError, (validateFormData[eachQuestion]?.['value'] > 100), typeof validateFormData[eachQuestion]?.['value'], validateFormData[eachQuestion]?.['dataSet']?.['isRequired'] && ((`${validateFormData[eachQuestion]?.['value']}`).length <= 0 || validateFormData[eachQuestion].value === null) || isNumberError);
          }
          if (validateFormData[eachQuestion]?.['dataSet']?.['isRequired'] && ((`${validateFormData[eachQuestion]?.['value']}`).length <= 0 || validateFormData[eachQuestion].value === null) || isNumberError) {
            validateFormData[eachQuestion]['dataSet']['isError'] = true
            validateFormData[eachQuestion]['dataSet']['errorMessage'] = isNumberError && (`${validateFormData[eachQuestion]?.['value']}`).length > 0 ?  (((validateFormData[eachQuestion]?.['value'] > 100) || (validateFormData[eachQuestion]?.['value'] < 0)) && (eachQuestion === "workExperience" || eachQuestion === "workExperienceInCE")) ? 'Input value range should be 0 to 100' : 'Invalid format' : 'Required'
            functionReturn = false
          } else {
            if (validateFormData[eachQuestion]['dataSet']) {
              validateFormData[eachQuestion]['dataSet']['isError'] = false
              validateFormData[eachQuestion]['dataSet']['errorMessage'] = ''
            }
          }
        }
        // Checking Required ------------------------------------------------------------------------------------------------------------------
  
  
        // Check Social Link ----------------------------------------------------------------------------------------------------
        if (eachQuestion === 'socialLink') {
          if (validateFormData[eachQuestion]['socialLink'] !== undefined) {
            // console.log("(EMAIL_REGEX).test(validateFormData[eachQuestion]['value']1: ", (EMAIL_REGEX).test(validateFormData[eachQuestion]['value']))
            const value = validateFormData[eachQuestion]['value']
            if (value.length > 0) {
              if (value.charAt(0) !== ' ') {
                let newLink = ''
                  if (value.toLocaleLowerCase().includes('https://') || value.toLocaleLowerCase().includes('http://')) {
                  } else {
                      newLink = `https://${value}`
                      // linkedinUrl = newLink
                      setFormData({...formData['linkedin'], 'value': newLink})
                  }
                  validateFormData[eachQuestion]['dataSet']['isError'] = false
                  validateFormData[eachQuestion]['dataSet']['errorMessage'] = ''
                } else {
                  validateFormData[eachQuestion]['dataSet']['isError'] = true
                  validateFormData[eachQuestion]['dataSet']['errorMessage'] = 'first character not be space'
                  functionReturn = false
              }
           }
          }
        }
        // Check Social Link ----------------------------------------------------------------------------------------------------
  
        if (eachQuestion === 'seekingEmployment') {
          if (validateFormData[eachQuestion]['value'] === "Yes") {
            validateFormData['optedPlacementSupport']['isVisible'] = true
            validateFormData['optedPlacementSupport']['dataSet']['isRequired'] = true
          } else {
            validateFormData['optedPlacementSupport']['isVisible'] = false
            validateFormData['optedPlacementSupport']['value'] = ''
            validateFormData['optedPlacementSupport']['dataSet']['isRequired'] = false
          }
        }
       
        if (eachQuestion === 'workExperienceInCE' && formData['workExperience']['value']) {
          if (validateFormData[eachQuestion]['value'] > parseInt(formData['workExperience']['value']) || isNumberError) {
            validateFormData[eachQuestion]['dataSet']['isError'] = true
            validateFormData[eachQuestion]['dataSet']['errorMessage'] = 'Please input a number lower than the question above.'
          } else {
            validateFormData[eachQuestion]['dataSet']['isError'] = false
            validateFormData[eachQuestion]['dataSet']['errorMessage'] = ''
          }
        }

        if (eachQuestion === 'highestEducation') {
          const values = ["Associates degree (2 years)", "Bachelor's degree (4 years)", "Graduate degree (post, masters)", "Doctorate"]

          if (values.includes(validateFormData[eachQuestion]['value'])) {
            validateFormData['studyArea']['isVisible'] = true
            validateFormData['studyArea']['dataSet']['isRequired'] = true
          } else {
            validateFormData['studyArea']['isVisible'] = false
            validateFormData['studyArea']['value'] = ''
            validateFormData['studyArea']['dataSet']['isRequired'] = false
          }
        }

        if (eachQuestion === 'validDriverLicense') {
          const values = ["Yes, standard license (Class D)", "Yes, CDL license (Class A, B or C)", "Both"]

          if (values.includes(validateFormData[eachQuestion]['value'])) {
            validateFormData['carAccess']['isVisible'] = true
            validateFormData['carAccess']['dataSet']['isRequired'] = true
          } else {
            validateFormData['carAccess']['isVisible'] = false
            validateFormData['carAccess']['value'] = ''
            validateFormData['carAccess']['dataSet']['isRequired'] = false
          }
        }
        
        if (eachQuestion === 'sourceApplication') {
          if (validateFormData[eachQuestion]['value'] === "Referral" || validateFormData[eachQuestion]['value'] === "Other") {
            validateFormData['referredBy']['isVisible'] = true
            validateFormData['referredBy']['dataSet']['isRequired'] = true
            if (validateFormData[eachQuestion]['value'] === "Referral") {
              validateFormData['referredBy']['dataSet']['question'] = 'If you chose Referral, who referred you?'
            } else {
              validateFormData['referredBy']['dataSet']['question'] = 'If you chose Other, please describe'
            }
          } else {
            validateFormData['referredBy']['isVisible'] = false
            validateFormData['referredBy']['value'] = ''
            validateFormData['referredBy']['dataSet']['isRequired'] = false
          }
        }

        if (eachQuestion === 'additionalCourse') {
          const valueArray = validateFormData[eachQuestion]['value']
          console.log("eachQuestion: ", eachQuestion, valueArray);
          if (valueArray.length !== 0) {
            if (valueArray.includes("None") && valueArray.length > 1 && valueArray[0] !== "None"){
              validateFormData[eachQuestion]['dataSet']['isError'] = false
              validateFormData[eachQuestion]['dataSet']['errorMessage'] = ''
              validateFormData[eachQuestion]['value'] = ['None']
            } else if (valueArray.includes("None") && valueArray[0] === "None"){
              if (valueArray.length > 1) {
                validateFormData[eachQuestion]['value'] = (validateFormData[eachQuestion]['value']).filter(item => item !== "None")
              }  else {
                validateFormData[eachQuestion]['value'] = ['None']
              }
            } else if (valueArray.includes("None")) {
              validateFormData[eachQuestion]['dataSet']['isError'] = false
              validateFormData[eachQuestion]['dataSet']['errorMessage'] = ''
              validateFormData[eachQuestion]['value'] = ['None']
            } else {
              validateFormData[eachQuestion]['dataSet']['isError'] = false
              validateFormData[eachQuestion]['dataSet']['errorMessage'] = ''
            }
          } else {
            validateFormData[eachQuestion]['dataSet']['isError'] = true
            validateFormData[eachQuestion]['dataSet']['errorMessage'] = 'Required'
            functionReturn = false
          }
        }
      }
    })
    setIsLoading(true)
    setTimeout(() => {
      setIsLoading(false)
    }, 500)
    setFormData(validateFormData)
    return functionReturn
  }

  // Submit Form ----------------------------------------------------------------------------------
  let dateString = new Date();
  dateString = moment(dateString).format('YYYY-MM-DD');
  const handleSubmit = () => {
    // console.log("validateData(): ", validateData());
    if (validateData()) {
      setLoader(true)
      addStudentsApplication({data: {
        fName: formData['fName']['value'],
        mName: formData['mName']['value'],
        phoneNumber: formData['phoneNumber']['value'],
        email: formData['email']['value'],
        address: formData['address']['value'],
        city: formData['city']['value'],
        stateShortcode: '',
        state: formData['state']['value'],
        zipcode: formData['zipcode']['value'],
        zipcodeString: `${formData['zipcode']['value']}`,
        linkedin: formData['linkedin']['value'],
        isEighteen: formData['isEighteen']['value'],
        
        
        courceInterested: formData['courceInterested']['value'],
        additionalCourse: (formData['additionalCourse']['value']).map(item => item).join(','),
        currentEmploymentStatus: formData['currentEmploymentStatus']['value'],
        
        workExperience: ""+formData['workExperience']['value'],
        workExperienceInCE: ""+formData['workExperienceInCE']['value'],

        pastExperienceAreas: (formData['pastExperienceAreas']['value']).map(item => item).join(','),
        certifications: ((formData['certifications']['value']).map(item => item).join(',')) + `${formData['certifications']['otherValue']}`,
        seekingEmployment: formData['seekingEmployment']['value'],
        optedPlacementSupport: formData['optedPlacementSupport']['value'],
        highestEducation: formData['highestEducation']['value'],

        studyArea: formData['studyArea']['value'],
        validDriverLicense: formData['validDriverLicense']['value'],
        carAccess: formData['carAccess']['value'],
        recentlyGraduated: formData['recentlyGraduated']['value'],
        NYSERDA_priorityPopulations: '',
        NYSERDA_disadvantagedCommunity: IsDisadvantage,
        sex: '',
        pronoun: '',
        ethnicity: '',
        sourceApplication: formData['sourceApplication']['value'],
        sourceApplicationAdditionalDescription: formData['sourceApplication']['extraDescription'],
        referredBy: formData['referredBy']['value'],
        date: dateString,
        resume: formData['resume']['value'],        
        // coverLetterUpload: formData['coverLetterUpload']['value'],
        versionName: "2025",
        formLanguage: localStorage.getItem('newFormFromLanguage') || 'en',
        emailCommunication: formData.email.emailCommunication,
        smsCommunication: formData.phoneNumber.smsCommunication,
        emailVerified: formData.emailVerified,
        phoneVerified: formData.phoneVerified,
        userData: userSystemInfo
      }}).then(res => {
        if (res !== null) {
          setShow(true)
          setModalName("Verify Your Email")
          
          toast.success(<Translate>Application Submitted Successfully</Translate>)
          if (formData.emailVerified) {
            handleReloadForm()
          }
          setInterval(() => {
            if (userEmailVerified) {
              handleReloadForm()
            } else {
              checkEmailVerify(formData['email']['value']).then(res => {
                // if (res !== null) {
                  if (res.status) {
                    setUserEmailVerified(true)
                    handleReloadForm()
                  } else {
                    setUserEmailVerified(false)
                  }
                // }
              })
            }
          }, 5000)
        } else {
          setLoader(false)
        }
      }).catch(error => {
        setLoader(false)
        console.log("error: ", error);
        toast.error(<Translate>Something went wrong</Translate>, {position: 'top-right', autoClose: 2000, hideProgressBar: true})
      })
    } else {
      toast.error(<Translate>Please Fill All The Required Fields</Translate>, {position: 'top-right', autoClose: 2000, hideProgressBar: true})
    }
  }

  const handleReloadForm = () => {
    setCancelClicked(true)
    setModalName('Thank You')
    setTimeout(() => {
      window.location.reload()
    }, 3000)
  }

  const handleResendEmail = () => {
    checkUserEmail({email: formData?.email.value, type: 'student'}).then(res => {
      if (res.status) {
        toast.success('Verification link re-send successfully', { position: 'top-right', autoClose: 2000, hideProgressBar: true })
        setResendEmailBtnClicked(true)
        handleReloadForm()
      } else {
        setResendEmailBtnClicked(false)
      }
    }).catch(error => {
      setResendEmailBtnClicked(false)
      console.log("error: ", error);
      toast.error('Something went wrong!! try again later', { position: 'top-right', autoClose: 2000, hideProgressBar: true })
    })
  }
  // Submit Form ----------------------------------------------------------------------------------

  // Dropdown Componenet
  const handleSelectState = (item) => {
    console.log(item);
    setFormData({...formData, state: {...formData['state'], 'value': item.value}})
  }

  // Study Area Dropdown Componenet
  const handleSelectStudyArea = (item) => {
    console.log(item);
    setFormData({...formData, studyArea: {...formData['studyArea'], 'value': item.value}})
  }

  // Address Component
  const handleAdderssByMap = () => {
    setShow(true)
  }

  const handleCancel = () => {
      setShow(false)
  }

  const [referencess, setReference] = useState(null)
  const onLoad = ref => {
    let searchBox = null
    searchBox = ref
    setReference(ref)
  };

  const search = async (googleAddress) => {
    console.log("googleAddress: ", googleAddress);

    const [Map, MapView, FeatureLayer, Search] = await loadModules([
      "esri/Map",
      "esri/views/MapView",
      "esri/layers/FeatureLayer",
      "esri/widgets/Search"
    ])

    try {
      const myMap = new Map({
      basemap: "streets-relief-vector"
      })

      console.log("map: ", myMap)
  
      const myView = new MapView({
          container: "viewDiv",
          map: myMap,
          center: [-76, 42.8], 
          zoom: 7
      })

      console.log("map 1: ", myView)
  
      myView.popup.dockOptions = {
      // Disable the dock button so users cannot undock the popup
      buttonEnabled: false
      }
  
      myView.ui._removeComponents(["attribution"])
  
      const Interim_DAC = new FeatureLayer({
      url: "https://services7.arcgis.com/ZR2wjW0JGwgm9bhz/arcgis/rest/services/FinalDisadvantagedCommunities/FeatureServer",
      popupTemplate: {
      // Enable a popup
      title: "Interim DAC" // Show attribute value
      // content: "Clicked Point" // Display text in pop-up
      }
      })

      myMap.add(Interim_DAC)           
      // console.log("map 2: ", Interim_DAC)
  
      const searchWidget = new Search({
      view: myView
      })

      myView.ui.add(searchWidget, {
          position: "top-left",
          index: 2
      })

      searchWidget.search(googleAddress).then(res => {
        // console.log("response: ", res?.results[0]['results'][0]['feature']['geometry']);
        const geometry = res?.results[0]['results'][0]['feature']['geometry']
        // console.log("geometry: ", res?.results[0]['results'][0]['feature']['geometry']);
        getAddress(geometry);
      }).catch(error => {
        // console.log("geometry error: ", error);
      })
      
      function resolveValue(value) {

        console.log("map 5: ", value);
          var geoId = null;
          var userMessage = "";
          console.log("value 12: ", value);
          if(value == null){
              userMessage = "";
              console.log("userMessage - " + userMessage);
              // alert('Address Not Meet')
              setIsDisadvantage('no')
              // document.getElementById('resultDiv').innerHTML = userMessage
          }
          else if(value == "no data found"){
              userMessage = " is outside a DAC";
              console.log("userMessage - " + userMessage);
              // alert('Address Not Meet')
              setIsDisadvantage('no')
              // document.getElementById('resultDiv').innerHTML = userMessage
          }
          else if(value.features.length > 0){
              geoId = value.features[0].attributes.GEOID;
              userMessage = "<br>"+ "This address meets the interim criteria identified for a disadvantaged community.";
              console.log("userMessage - " + userMessage);
              // alert('Address Meet')
              setIsDisadvantage('yes')
              // document.getElementById('resultDiv').innerHTML = userMessage
          }
          else{
              userMessage = "<br>"+ "This address does not meet the interim criteria identified for a disadvantaged community.";
              console.log("userMessage - " + userMessage);
              // alert('Address Not Meet')
              setIsDisadvantage('no')
              // document.getElementById('resultDiv').innerHTML = userMessage
          }
      }

      function getAddress(point) {
          const query = Interim_DAC.createQuery()
          query.geometry = point  
          query.spatialRelationship = "intersects"
          query.returnGeometry = false
          query.outFields = ["GEOID"]
          Interim_DAC.queryFeatures(query)
          .then(function(value) {
              resolveValue(value)
          }, function(err) {
              console.error("This is an error", err)
              // this.error()
          })
      }
  
      searchWidget.on("select-result", function(event) {
          // setIsAddressSearched(true)
          getAddress(event.result.feature.geometry)
      })
      searchWidget.on("search-clear", function() {
          // console.log("cleared")
          // setIsAddressSearched(false)
          // setIsDisadvantage(false)
      })
    } catch (error) {
      // alert('Address Not Meet')
      setIsDisadvantage('no')
    }
  }

  const onPlacesChanged = () => {
    let selectedAddress = referencess.getPlaces()
    let latitude = selectedAddress[0].geometry.location.lat()
    let longitude = selectedAddress[0].geometry.location.lng()

    let addressComponentsArray = [];
    addressComponentsArray = selectedAddress[0].address_components;
    let zipcode = ''
    let state = ''
    let city = ''
    for (let i = 0 ; i < addressComponentsArray.length ; i++) {
      let addObjtypes = addressComponentsArray[i].types
      if (addObjtypes.includes('postal_code')) {
          zipcode = `${addressComponentsArray[i].long_name}`
          console.log("addObjtypes: ", addressComponentsArray[i].long_name);
          // break;
      }
      
      if (addObjtypes.includes('administrative_area_level_1')) {
          state = `${addressComponentsArray[i].long_name}`
          console.log("addObjtypes: ", addressComponentsArray[i].long_name);
          // break;
      }

      if (addObjtypes.includes('locality')) {
        city = addressComponentsArray[i].long_name
      }
    }
    
    console.log("event: ", selectedAddress[0].geometry.location.lat(), selectedAddress[0].geometry.location.lng(), zipcode)
    setLat(latitude); setLng(longitude);
    setFormData({...formData, address: {...formData['address'], 'value': selectedAddress[0].formatted_address}, zipcode: {...formData['zipcode'], 'value': zipcode}, state: {...formData['state'], 'value': state}, city: {...formData['city'], 'value': city}})
    search(selectedAddress[0].formatted_address)
  };

  const setAddressByMap = (addressComponent) => {
    setFormData({...formData, address: {...formData['address'], 'value': addressComponent.formattedAddress}, zipcode: {...formData['zipcode'], 'value': addressComponent.zipcode}, state: {...formData['state'], 'value': addressComponent.state}, city: {...formData['city'], 'value': addressComponent.city}})
    setLat(addressComponent.latitude)
    setLng(addressComponent.longitude)
    search(addressComponent.formattedAddress)
  }

  // Prefil Form Data
  const prefillApplicationForm = (data) => {
    const userData = {...data.attributes, 
      // studyArea: '',
      // validDriverLicense: '',
      // carAccess: '',
    }

    // Check Data For RadioHavingChildrens Component
    let questionOptions = []
    if (userData.currentEmploymentStatus) {
      let value = (userData.currentEmploymentStatus).split(',').map(element => element.trim()) || []

      // if (formData.currentEmploymentStatus.dataSet.options)
      questionOptions = formData.currentEmploymentStatus.dataSet.options
      for (let i = 0 ; i < questionOptions.length; i++) {
        for (let j = 0 ; j < value.length; j++) {
          if ((questionOptions[i].childrens).includes(value[j])) {
            questionOptions[i]['showChildren'] = true
          }
        }
      }
    } else {
      questionOptions = formData.currentEmploymentStatus.dataSet.options
    }

    // Format ethnicity
    let ethinicityValue = '';
    Object.keys(ethnicitiesDynamic).forEach(key => {
      if ((ethnicitiesDynamic[key]).includes(userData.ethnicity)) {
        ethinicityValue = key
      }
    })

    // console.log('nyserda values:------', nyserdaValue, originalNyserdaValues, userData);

    setFormData({...formData,
      fName: {...formData['fName'], 'value': userData.fName, dataSet: {...formData['fName']['dataSet'], isError: false, errorMessage: ''}, emailCommunication: userData.emailCommunication},
      mName: {...formData['mName'], 'value': userData.mName, dataSet: {...formData['mName']['dataSet'], isError: false, errorMessage: ''}},
      phoneNumber: {...formData['phoneNumber'], 'value': userData.phoneNumber, dataSet: {...formData['phoneNumber']['dataSet'], isError: false, errorMessage: ''}, smsCommunication: userData.smsCommunication},
      address: {...formData['address'], 'value': userData.address, dataSet: {...formData['address']['dataSet'], isError: false, errorMessage: ''}},
      state: {...formData['state'], 'value': userData.state, dataSet: {...formData['state']['dataSet'], isError: false, errorMessage: ''}},
      city: {...formData['city'], 'value': userData.city, dataSet: {...formData['city']['dataSet'], isError: false, errorMessage: ''}},
      zipcode: {...formData['zipcode'], 'value': userData.zipcode, dataSet: {...formData['zipcode']['dataSet'], isError: false, errorMessage: ''}},
      linkedin: {...formData['linkedin'], 'value': userData.linkedin, dataSet: {...formData['linkedin']['dataSet'], isError: false, errorMessage: ''}},
      isEighteen: {...formData['isEighteen'], 'value': userData.isEighteen, dataSet: {...formData['isEighteen']['dataSet'], isError: false, errorMessage: ''}},
      
      courceInterested: {...formData['courceInterested'], 'value': userData.courceInterested, dataSet: {...formData['courceInterested']['dataSet'], isError: false, errorMessage: ''}},
      additionalCourse: {...formData['additionalCourse'], 'value': userData.additionalCourse ? (userData.additionalCourse).split(',').map(element => element.trim()) : [], dataSet: {...formData['additionalCourse']['dataSet'], isError: false, errorMessage: ''}},
      currentEmploymentStatus: {...formData['currentEmploymentStatus'], 'value': userData.currentEmploymentStatus ? (userData.currentEmploymentStatus) : [], dataSet: {...formData['currentEmploymentStatus']['dataSet'], isError: false, errorMessage: '', options: questionOptions}},
      
      workExperience: {...formData['workExperience'], 'value': parseInt(userData.workExperience), dataSet: {...formData['workExperience']['dataSet'], isError: false, errorMessage: ''}},
      workExperienceInCE: {...formData['workExperienceInCE'], 'value': parseInt(userData.workExperienceInCE), dataSet: {...formData['workExperienceInCE']['dataSet'], isError: false, errorMessage: ''}},

      pastExperienceAreas: {...formData['pastExperienceAreas'], 'value': userData.pastExperienceAreas ? (userData.pastExperienceAreas).split(',').map(element => element.trim()) : [], dataSet: {...formData['pastExperienceAreas']['dataSet'], isError: false, errorMessage: ''}},
      certifications: {...formData['certifications'], 'value': userData.certifications ? (userData.certifications).split(',').map(element => element.trim()) : null, dataSet: {...formData['certifications']['dataSet'], isError: false, errorMessage: ''}},
      seekingEmployment: {...formData['seekingEmployment'], 'value': userData.seekingEmployment ? userData.seekingEmployment : '', dataSet: {...formData['seekingEmployment']['dataSet'], isError: false, errorMessage: ''}},
      optedPlacementSupport: {...formData['optedPlacementSupport'], 'value': userData.optedPlacementSupport ? userData.optedPlacementSupport : '', dataSet: {...formData['optedPlacementSupport']['dataSet'], isError: false, errorMessage: ''}},
      highestEducation: {...formData['highestEducation'], 'value': userData.highestEducation, dataSet: {...formData['highestEducation']['dataSet'], isError: false, errorMessage: ''}},
      

      studyArea: {...formData['studyArea'], 'value': userData?.studyArea || '' , dataSet: {...formData['studyArea']['dataSet'], isError: false, errorMessage: ''}, isVisible: (userData.highestEducation === "Associates Degrees" || userData.highestEducation === "Bachelor's Degrees" || userData.highestEducation === "Graduate Degree" || userData.highestEducation === "Doctorate") || false},
      validDriverLicense: {...formData['validDriverLicense'], 'value': userData?.validDriverLicense, dataSet: {...formData['validDriverLicense']['dataSet'], isError: false, errorMessage: ''}, isVisible: true},
      carAccess: {...formData['carAccess'], 'value': userData?.carAccess || '' , dataSet: {...formData['carAccess']['dataSet'], isError: false, errorMessage: ''}, isVisible: userData.validDriverLicense === "Yes, standard license (Class D)" || userData.validDriverLicense === "Yes, CDL license (Class A, B or C)" || false},
      
      recentlyGraduated: {...formData['recentlyGraduated'], 'value': userData.recentlyGraduated !== null ? userData.recentlyGraduated : '', dataSet: {...formData['recentlyGraduated']['dataSet'], isError: false, errorMessage: ''}},
      // NYSERDA_priorityPopulations: {...formData['NYSERDA_priorityPopulations'], 'value': '', dataSet: {...formData['NYSERDA_priorityPopulations']['dataSet'], isError: false, errorMessage: ''}},
      NYSERDA_disadvantagedCommunity: userData?.NYSERDA_disadvantagedCommunity || 'no',
      
      // sex: {...formData['sex'], 'value': userData.sex,'stateShortcode': userData.stateShortcode || '',  dataSet: {...formData['sex']['dataSet'], isError: false, errorMessage: ''}},
      // pronoun: {...formData['pronoun'], 'value': userData.pronoun !== null && userData.pronoun !== undefined ? userData.pronoun : '', dataSet: {...formData['pronoun']['dataSet'], isError: false, errorMessage: ''}},
      
      // ethnicity: {...formData['ethnicity'], 'value': ethinicityValue, dataSet: {...formData['ethnicity']['dataSet'], isError: false, errorMessage: ''}},
      
      sourceApplication: {...formData['sourceApplication'], 'value': userData.sourceApplication, extraDescription: userData?.sourceApplicationAdditionalDescription || 'Hi', dataSet: {...formData['sourceApplication']['dataSet'], isError: false, errorMessage: ''}},
      sourceApplicationAdditionalDescription: userData?.sourceApplicationAdditionalDescription || '',
      referredBy: {...formData['referredBy'], 'value': userData.referredBy, dataSet: {...formData['referredBy']['dataSet'], isError: false, errorMessage: '', isRequired: userData.sourceApplication === "Referral" || userData.sourceApplication === "Other"}, isVisible: userData.sourceApplication === "Referral" || userData.sourceApplication === "Other"},
      date: dateString,

      resume: {...formData['resume'], 'value': userData.resume, dataSet: {...formData['resume']['dataSet'], isError: false, errorMessage: ''}},
      // coverLetterUpload: {...formData['coverLetterUpload'], 'value': userData.coverLetterUpload ? userData.coverLetterUpload : '', dataSet: {...formData['coverLetterUpload']['dataSet'], isError: false, errorMessage: ''}},
      versionName: "2025",
      formLanguage: localStorage.getItem('newFormFromLanguage') || 'en',

      emailCommunication: userData.emailCommunication,
      smsCommunication: userData.smsCommunication,
      emailVerified: userData.emailVerified,
      phoneVerified: userData.phoneVerified,

      userData: userSystemInfo
    })

    setIsDisadvantage(userData?.NYSERDA_disadvantagedCommunity || 'no')
  }

  const getContent = (questionObject, singleOrMulti) => {
    if (questionObject !== null) {
      switch(questionObject.type) {
  
        case "simpleRadio":  return (
          <SimpleRadio className={singleOrMulti} item={questionObject['dataSet']} formType="v3_applications" btnDisabled={btnDisabled} completeQuestionData={questionObject} validateData={validateData} formData={formData}/>
        )
  
        case "simpleCheckbox":  return(
          <Checkbox className={singleOrMulti} item={questionObject['dataSet']} btnDisabled={btnDisabled} completeQuestionData={questionObject} validateData={validateData}/>
        )
        
        case "simpleInput":  return(
          <Input className={singleOrMulti} setUserExistsData={setUserExistsData} item={questionObject} btnDisabled={btnDisabled} formType="student" setBtnDisabled={setBtnDisabled} prefillApplicationForm={prefillApplicationForm} completeQuestionData={questionObject} validateData={validateData} formData={formData} setFormData={setFormData}/>
        )
        
        case "dropdown":  return (
          <div className={`single-group-child ${singleOrMulti === "single-element" ? singleOrMulti : ''} ${questionObject.dataSet['isError'] ? 'error-in-question' : ''}`}>
            <strong className='strongclass'><Translate>{questionObject.dataSet.question}</Translate><span className='mandatory'>*</span></strong>
            <div className='ui form'>
              <div className='field'>
                  <CreatableSelect options={states} isDisabled={btnDisabled} placeholder="Select State" className='react-select' styles={{width: '100%'}} value={{label: formData.state.value, value: formData.state.value, isFixed: true}} classNamePrefix='select' onChange={(item) => handleSelectState(item)}/>
              </div>
          </div> 
          {questionObject['dataSet']['isError'] ? <p className='error-message-container'><Translate>{questionObject['dataSet']['errorMessage']}</Translate></p> : null}
          </div>
        )

        case "dropdown-studyArea":  return (
          <div className={`single-group-child ${singleOrMulti === "single-element" ? singleOrMulti : ''} ${questionObject.dataSet['isError'] ? 'error-in-question' : ''} ${questionObject?.isVisible !== undefined && !questionObject?.isVisible ? 'hide-question' : null}`}>
            <strong className='strongclass'><Translate>{questionObject.dataSet.question}</Translate><span className='mandatory'>*</span></strong>
            <div className='ui form'>
              <div className='field'>
                  <CreatableSelect options={highestEducationCertificates} isDisabled={btnDisabled} placeholder="Select State" className='react-select' styles={{width: '100%'}} value={{label: formData.studyArea.value, value: formData.studyArea.value, isFixed: true}} classNamePrefix='select' onChange={(item) => handleSelectStudyArea(item)}/>
              </div>
          </div> 
          {questionObject['dataSet']['isError'] ? <p className='error-message-container'><Translate>{questionObject['dataSet']['errorMessage']}</Translate></p> : null}
          </div>
        )
  
        case "simpleInputWithMap": return (
          <div className={`single-group-child ${singleOrMulti === "single-element" ? singleOrMulti : ''} ${questionObject.dataSet['isError'] ? 'error-in-question' : ''}`}>
          <strong className='strongclass'><Translate>{questionObject.dataSet.question}</Translate><span className='mandatory'>*</span>
          {!btnDisabled ? 
          <Badge role={'button'} className="float-end" color='warning' style={{color: 'black'}} onClick={() => handleAdderssByMap()}><MapPin className="primary" size={18} stroke="black"/><Translate>Locate on Map</Translate></Badge>
            // <span className="fs-4" role={'button'} onClick={() => handleAdderssByMap()}>{`(Click to Locate on Map)`}</span>
            : null
          }
          </strong> 
          <div className='ui form'>
            <div className='field'>
            <StandaloneSearchBox onLoad={onLoad} onPlacesChanged={onPlacesChanged}>
                <input type="text" value={formData.address.value} disabled={btnDisabled} className="form-control" name="address" id="address" placeholder="Enter Address" onChange={(event) => setFormData({...formData, ['address']: {...formData['address'], 'value': event.target.value}})}/>
            </StandaloneSearchBox>
            </div>
        </div> 
        {questionObject['dataSet']['isError'] ? <p className='error-message-container'><Translate>{questionObject['dataSet']['errorMessage']}</Translate></p> : null}
        </div>
        )
  
        case "radioWithOther":  return (
            <SimpleRadioWithOther className={singleOrMulti} item={questionObject} btnDisabled={btnDisabled} completeQuestionData={questionObject} validateData={validateData}/>
            // <Input item={questionObject} completeQuestionData={questionObject} validateData={validateData}/>
        )
  
        case "checkboxWithOther":  return (
            <SimpleCheckboxWithOther className={singleOrMulti} item={questionObject} btnDisabled={btnDisabled} completeQuestionData={questionObject} validateData={validateData}/>
            // <Input item={questionObject} completeQuestionData={questionObject} validateData={validateData}/>
        )
        
        case "upload":  return (
            <Upload className={singleOrMulti} file={formData.resume.value} setLoader={setLoader} item={questionObject} email={formData.email.value} btnDisabled={btnDisabled} completeQuestionData={questionObject} validateData={validateData}/>
            // <Input item={questionObject} completeQuestionData={questionObject} validateData={validateData}/>
        )
       
        case "dob":  return (
            <DOB className={singleOrMulti} file={formData.resume.value} item={questionObject} email={formData.email.value} btnDisabled={btnDisabled} completeQuestionData={questionObject} validateData={validateData}/>
            // <Input item={questionObject} completeQuestionData={questionObject} validateData={validateData}/>
        ) 
       
        case "radioWithExpander":  return (
          <RadioHavingOptionChildren className={singleOrMulti} item={questionObject['dataSet']} btnDisabled={btnDisabled} completeQuestionData={questionObject} validateData={validateData}/>
            // <Input item={questionObject} completeQuestionData={questionObject} validateData={validateData}/>
        )
      }
    }
  }

  return (
    <div className='card'>
      <div className='formbg'>
      <script src='./analytics.js'></script>
      <div id='viewDiv' style={{height: '10px', opacity: 0}}>

      </div>
      <Paragraph formName={"studentapplicationv3"}/>
        <div className='single-elements-container'>
            {formData.additionalCourse.dataSet.options.length ? 
              Object.keys(formData).map((eachQuestion, i) => {
              if (i <= 7) {
                  return (
                  <div>
                      {getContent(formData[eachQuestion], 'single-element')}
                  </div>
                )
              }
              }) : null
            }
        </div>
        
        {formData.additionalCourse.dataSet.options.length ? 
        Object.keys(formData).map((eachQuestion, i) => {
          if (i > 7) {
              return (
              <div>
                {getContent(formData[eachQuestion])}
              </div>
            )
          }
          }) : null
        }
        <div className='single-submit-button'>
          <FormButton text="Submit" handleSubmit={() => handleSubmit()} loading={loader}/>
        </div>
      </div>

      
      <Modal isOpen={show} onClosed={() => handleCancel()} className="modal-dialog-centered">
          <ModalHeader>
            <Translate>
              {modalName !== "Thank You" && modalName !== "Verify Your Email" ? 'Mark Address' : modalName}
            </Translate>
            </ModalHeader>
          <ModalBody>
            {modalName === "Thank You" ? <div>
                <div className='d-flex flex-column justify-evenly align-items-center'>
                  <img src={FormSubmitted} style={{height: '100px', width: '100px'}}></img>
                  <p className='text-center fs-3'><Translate>Thank You!! Your response has been submitted</Translate></p>
                </div>
            </div> : modalName === "Verify Your Email" ? <div>
                <div className='d-flex flex-column justify-evenly align-items-center'>
                  {!formData.emailVerified ? 
                    <>
                        <p className='text-justify fs-5'><Translate>Check your email for a verification link from Willdan Clean Energy Academy CMS: Email Verification. Make sure to click on the link received to complete your submission.</Translate></p>
                        
                        <div className='d-flex justify-content-end w-100 mt-4'>
                          <button style={{backgroundColor: `${Colors.primary} !important`}} className="button-primary" disabled={resendEmailBtnClicked} onClick={() => handleResendEmail()}><Translate>Re-send Email</Translate></button>
                          <button className='mx-2 button-secondary' onClick={() => handleReloadForm()}><Translate>Cancel</Translate></button>
                        </div>
                    </> : null
                  }
                </div>
            </div> : <GoogleMapAddress handleCancel={handleCancel} setAddressByMap={setAddressByMap}></GoogleMapAddress>
            }
          </ModalBody>
      </Modal>
    </div>
  )
}

export default StudentApplicationV3