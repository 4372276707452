import React from 'react'
import Willimg from '../willimg.png'
import WeActLogo from './../weact_logo.png'
import SoulfulLogo from './../soulful_logo.png'
import rightimg from '../rightimg.svg'
import '../Header.css'
import { useLocation } from 'react-router-dom'
import { useState } from 'react'
import { useEffect } from 'react'
import Switch from "react-switch";
import { Translate } from 'react-auto-translate'
const Header = () => {

  const {pathname} = useLocation();
  const [checked, setChecked] = useState(false)


  console.log("pathname: ", pathname);

  useEffect(() => {
    const selectedLanguage = localStorage.getItem('newFormToLanguage') !== null ? localStorage.getItem('newFormToLanguage') : 'es'
    if (selectedLanguage === "en") {
      setChecked(true)
    } else {
      setChecked(false)
    }
  }, [])

  const handleChangeLanguage = (checked) => {
    setChecked(checked)
    const selectedLanguage = localStorage.getItem('newFormToLanguage') !== null ? localStorage.getItem('newFormToLanguage') : 'es'
    let toLanguage = ''
    let fromLanguage = ''
    if (selectedLanguage === "en") {
      toLanguage = 'es'
      fromLanguage = 'en'
    } else {
      fromLanguage = 'es'
      toLanguage = 'en'
    }
    localStorage.setItem('newFormToLanguage', toLanguage)
    localStorage.setItem('newFormFromLanguage', toLanguage)
    window.location.reload()
  }

  if(pathname === '/'){
    return (
      <div className='header d-flex flex-column'>
        <div>
          <img src={Willimg} alt="willdan" className='willimg' />
          <h3 className='rightimage'>
              <img src={rightimg} alt="cenergy forms" />
              <Translate>CENERGY</Translate>
              <br />
              <Translate>
                FORMS
              </Translate>
          </h3>
        </div>
          <p className='text-end mt-1'>
            <Switch className='language-switch' onChange={handleChangeLanguage} width={100} checked={checked} uncheckedIcon={<span>Spanish</span>} checkedIcon={<span>English</span>}/>
          </p>
      </div>
    )
  }
  else if(pathname === '/application' || pathname === '/v3/application' || pathname === '/test-applications'){
    return(
      <div className='header d-flex flex-column'>
        <div>
          <img src={Willimg} alt="willdan" className='willimg' />
          <h3 className='rightimage'>
              <img src={rightimg} alt="cenergy forms" />
              <Translate>
                STUDENT
              </Translate>
              <br />
              <Translate>
                APPLICATION
              </Translate>
          </h3>
      </div>
      <p className='text-end'>
            <Switch className='language-switch' onChange={handleChangeLanguage} width={100} checked={checked} uncheckedIcon={<span>Spanish</span>} checkedIcon={<span>English</span>}/>
          </p>
     </div>
    )
    
  }
  else if(pathname === '/elevator'){
    
    return(
      <div className='header d-flex flex-column'>
        <div>
          <img src={Willimg} alt="willdan" className='willimg' />
          <h3 className='rightimage'>
              <img src={rightimg} alt="cenergy forms" />
              <Translate>
                ELEVATOR
              </Translate>
              <br />
              <Translate>
                PITCH
              </Translate>
          </h3>   
      </div>
      <p className='text-end'>
       <Switch className='language-switch' onChange={handleChangeLanguage} width={100} checked={checked} uncheckedIcon={<span>Spanish</span>} checkedIcon={<span>English</span>}/>
      </p>
    </div>
    )
  }
  else if(pathname === '/training-pre-survey' || pathname === '/v3/training-pre-survey'){
    return(
      <div className='header d-flex flex-column'>
        <div>
          <img src={Willimg} alt="willdan" className='willimg' />
          <h3 className='rightimage'>
              <img src={rightimg} alt="cenergy forms" />
              <Translate>
                TRAINING
              </Translate>
              <br />
              <Translate>
                PRE SURVEY
              </Translate>
          </h3>   
      </div>
        <p className='text-end mt-1'>
          <Switch className='language-switch' onChange={handleChangeLanguage} width={100} checked={checked} uncheckedIcon={<span>Spanish</span>} checkedIcon={<span>English</span>}/>
        </p>
      </div>
    )
  }
  else if(pathname === '/training-post-survey' || pathname === '/v3/training-post-survey'){
    return(
      <div className='header d-flex flex-column'>
        <div>
          <img src={Willimg} alt="willdan" className='willimg' />
          <h3 className='rightimage'>
              <img src={rightimg} alt="cenergy forms" />
              <Translate>
                TRAINING
              </Translate>
              <br />
              <Translate>
                POST SURVEY
              </Translate>
          </h3>   
      </div>
       <p className='text-end mt-1'>
        <Switch className='language-switch' onChange={handleChangeLanguage} width={100} checked={checked} uncheckedIcon={<span>Spanish</span>} checkedIcon={<span>English</span>}/>
       </p>
      </div>
    )
  }
  else if(pathname === '/adny-satisfaction-survey'){
    return(
      <div className='header d-flex flex-column'>
        <div>
          <img src={Willimg} alt="willdan" className='willimg' />
          <h3 className='rightimage'>
              <img src={rightimg} alt="cenergy forms" />
              <Translate>
                ADNY Occupant
              </Translate>
              <br />
              <Translate>
                Satisfaction Survey
              </Translate>
          </h3>   
      </div>
        <p className='text-end mt-1'>
          <Switch className='language-switch' onChange={handleChangeLanguage} width={100} checked={checked} uncheckedIcon={<span>Spanish</span>} checkedIcon={<span>English</span>}/>
        </p>
      </div>
    )
  }
  else if(pathname === '/willdan-registration-form'){
    return(
      <div className='header d-flex flex-column'>
        <div>
          <img src={Willimg} alt="willdan" className='willimg' />
          <h3 className='rightimage'>
              <img src={rightimg} alt="cenergy forms" />
              <Translate>
                Willdan Staff
              </Translate>
              <br />
              <Translate>
                Registration Form
              </Translate>
          </h3>   
      </div>
        <p className='text-end mt-1'>
          <Switch className='language-switch' onChange={handleChangeLanguage} width={100} checked={checked} uncheckedIcon={<span>Spanish</span>} checkedIcon={<span>English</span>}/>
        </p>
      </div>
    )
  }
  else if(pathname === '/adny-registration'){
    return(
      <div className='header d-flex flex-column'>
        <div>
          <img src={Willimg} alt="willdan" className='willimg' />
          <h3 className='rightimage d-flex justify-content-evenly align-items-center'>
              <img src={rightimg} alt="cenergy forms" />
              <Translate>
                ADNY Registration
              </Translate>
              {/* <br />
              <Translate>
                Satisfaction Survey
              </Translate> */}
          </h3>   
      </div>
        <p className='text-end mt-1'>
          <Switch className='language-switch' onChange={handleChangeLanguage} width={100} checked={checked} uncheckedIcon={<span>Spanish</span>} checkedIcon={<span>English</span>}/>
        </p>
      </div>
    )
  }
  else if(pathname === '/we-act-application'){
    return(
      <div className='header-weact d-flex flex-column'>
        <div className='responsive-flex justify-content-between'>
          <img src={WeActLogo} alt="weact" className='weactimg' />
          <div className='d-flex flex-column flex-end'>
            <h3 className='rightimage text-align-end text-dark fw-bolder'>
                {/* <img src={rightimg} alt="cenergy forms" /> */}
                <Translate>
                  STUDENT APPLICATION
                </Translate>
                {/* <br />
                <Translate>
                  APPLICATION
                </Translate> */}
            </h3>   
            <p className='text-end mt-1'>
              <Switch className='language-switch' onChange={handleChangeLanguage} width={100} checked={checked} uncheckedIcon={<span>Spanish</span>} checkedIcon={<span>English</span>}/>
            </p>
          </div>
      </div>
      </div>
    )
  }
  else if(pathname === '/uploads'){
    return(
      <div className='header d-flex flex-column'>
        <div>
          <img src={Willimg} alt="willdan" className='willimg' />
          <h3 className='rightimage'>
              <img src={rightimg} alt="cenergy forms" />
              <Translate>
                Uploads
              </Translate>
              <br />
          </h3>   
      </div>
       <p className='text-end mt-1'>
        <Switch className='language-switch' onChange={handleChangeLanguage} width={100} checked={checked} uncheckedIcon={<span>Spanish</span>} checkedIcon={<span>English</span>}/>
       </p>
      </div>
    )
  } 
  else if (pathname === "/soulful-student-application") {
    // src/soulful_logo.png
    return(
      <div className='header d-flex flex-column header-soulful'>
        <div>
          <img src={SoulfulLogo} alt="soulfulimg" className='soulfulimg' />
          <div className='rightimage text-align-end text-dark fw-bolder d-flex flex-column'  style={{fontSize: '20px'}}>
              <Translate>
                STUDENT APPLICATION
              </Translate>
              <span className='text-end mt-1'>
                <Switch className='language-switch-soulful' onChange={handleChangeLanguage} width={100} checked={checked} uncheckedIcon={<span>Spanish</span>} checkedIcon={<span>English</span>}/>
              </span>
          </div>   
      </div>
      </div>
    )
  }
  else if (pathname === '/adny'){
    return(
      <div className='header d-flex flex-column'>
        <div>
          <img src={Willimg} alt="willdan" className='willimg' />
          <h3 className='rightimage'>
              <img src={rightimg} alt="cenergy forms" />
              <Translate>
                ADNY
              </Translate>
              <br />
              <Translate>
                FORMS
              </Translate>
          </h3>   
      </div>
       <p className='text-end mt-1'>
        <Switch className='language-switch' onChange={handleChangeLanguage} width={100} checked={checked} uncheckedIcon={<span>Spanish</span>} checkedIcon={<span>English</span>}/>
       </p>
      </div>
    )
  } else if (pathname === '/public/files'){
    return(
      <div className='header d-flex flex-column'>
        <div>
          <img src={Willimg} alt="willdan" className='willimg' />
          <h3 className='rightimage'>
              <img src={rightimg} alt="cenergy forms" />
              <Translate>
                VIEW
              </Translate>
              <br/>
              <Translate>
                FILE
              </Translate>
          </h3>   
      </div>
       {/* <p className='text-end mt-1'>
        <Switch className='language-switch' onChange={handleChangeLanguage} width={100} checked={checked} uncheckedIcon={<span>Spanish</span>} checkedIcon={<span>English</span>}/>
       </p> */}
      </div>
    )
  }
  
  else{
    return(
      <div className='header d-flex flex-column'>
        <div>
          <img src={Willimg} alt="willdan" className='willimg' />
          <h3 className='rightimage'>
              <img src={rightimg} alt="cenergy forms" />
              <Translate>
                Error
              </Translate>
              <br />
          </h3>   
      </div>
       <p className='text-end mt-1'>
        <Switch className='language-switch' onChange={handleChangeLanguage} width={100} checked={checked} uncheckedIcon={<span>Spanish</span>} checkedIcon={<span>English</span>}/>
       </p>
      </div>
    )
  }
}

export default Header