import React from 'react'
import { Translate } from 'react-auto-translate'
import '../Inputs.css'
const Paragraph = ({formName}) => {

  switch (formName) {
    case 'studentapplicationv3': return (
      <div>
          <h3 className='centertext'>
            <Translate>
              Welcome to the Willdan Clean Energy Academy!
          </Translate>
          </h3>
        <p className='para'>
          <Translate>
            Clean Energy Academy Program Overview: This is a FREE energy efficiency technical training, combined with an online Dynamic Learning Management System delivery method. The program focuses on energy efficiency outreach, education and implementation assistance initiatives for small commercial properties.
          </Translate>
        {/* <br /><br />
        <Translate>
          30 or 60 Hours of Clean Energy Technical Training
        </Translate>
        <br />
        <ol>
          <li><Translate>Learn real world application of sustainability policies and standards</Translate></li>
          <li><Translate>Understand proper use of software tools and energy audit processes</Translate></li>
          <li><Translate>Build your portfolio of energy audit reports and analyses</Translate></li>
          <li><Translate>Leverage experience based and service based learning opportunities</Translate></li>
          <li><Translate>Prepare for nationally recognized industry certifications</Translate></li>
          <li><Translate>Internship Program and Job Placement Services</Translate></li>
          <li><Translate>Professional Development/Resume Writing and Support Services</Translate></li><br />
        </ol>
        <Translate>
          Upon completion of this application you will receive an email from our outreach team for you to schedule your interview. (Check your spam folder in case) Look forward to seeing you soon!
        </Translate> */}
        </p>
        <span className='formheading'>
          <Translate>
            APPLICANT INFORMATION
          </Translate>
        </span><br /><br />
      </div>
    )

    default: return (
      <div>
          <h3 className='centertext'>
            <Translate>
              Welcome to the Willdan Clean Energy Academy!
          </Translate>
          </h3>
        <p className='para'><Translate>
           Clean Energy Academy Program Overview: 
        This is a FREE energy efficiency technical training, combined with 
        an online Dynamic Learning Management System delivery method. 
        The program focuses on energy efficiency outreach, education and implementation assistance initiatives for small commercial properties.
          </Translate>
        <br /><br />
        <Translate>
          30 or 60 Hours of Clean Energy Technical Training
        </Translate>
        <br />
        <ol>
          <li><Translate>Learn real world application of sustainability policies and standards</Translate></li>
          <li><Translate>Understand proper use of software tools and energy audit processes</Translate></li>
          <li><Translate>Build your portfolio of energy audit reports and analyses</Translate></li>
          <li><Translate>Leverage experience based and service based learning opportunities</Translate></li>
          <li><Translate>Prepare for nationally recognized industry certifications</Translate></li>
          <li><Translate>Internship Program and Job Placement Services</Translate></li>
          <li><Translate>Professional Development/Resume Writing and Support Services</Translate></li><br />
        </ol>
        <Translate>
          Upon completion of this application you will receive an email from our outreach team for you to schedule your interview. (Check your spam folder in case) Look forward to seeing you soon!
        </Translate>
        </p>
        <span className='formheading'>
          <Translate>
            APPLICANT INFORMATION
          </Translate>
        </span><br /><br />
      </div>
    )
  }

  
}

export default Paragraph
