import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { Translate } from 'react-auto-translate'
import './index.css'
import { GetCityByZipcode } from '../../../Apis/generalApis'

const SimpleRadio = (props) => {

    // States
    const [formSelectedLanguage, setFormSelectedLanguage] = useState('')
    const handleChange = (event) => {
        console.log("complete question data:------------------- ", props?.completeQuestionData, props?.formData);
        if (props?.completeQuestionData.dataSet?.formDataKey === "sex" && (`${props?.formData?.zipcode?.value || ''}`).length >= 4 && (props?.formData?.zipcode?.value).length <= 5) {
            // console.log("simple radio:-----------------------", props?.completeQuestionData.dataSet?.formDataKey, props?.completeQuestionData);
            if ((props?.formData?.zipcode?.value).charAt(0) === "0") {
                GetCityByZipcode(`${(props?.formData?.zipcode?.value).substring(1)}`).then(res => {
                    if (res.data) {
                    // console.log("city res:--------------------- ", res)
                    const data = res.data
                    if (data.length) {
                        const city = data[0]['attributes']['city']
                        const stateShortcode = data[0]['attributes']['state']
                        // console.log("city:--------------------------", city);
                        props.completeQuestionData.city = city
                        props.completeQuestionData.stateShortcode = stateShortcode
                    }} else {
                     props.completeQuestionData.city = ""
                    }
                }).catch(error => {
                    console.log("error while getting city from zipcode:", error);
                })
            } else {
                GetCityByZipcode(props?.formData?.zipcode?.value).then(res => {
                    if (res.data) {
                    // console.log("city res:--------------------- ", res)
                    const data = res.data
                    if (data.length) {
                        const city = data[0]['attributes']['city']
                        const stateShortcode = data[0]['attributes']['state']
                        // console.log("city:--------------------------", city);
                        props.completeQuestionData.city = city
                        props.completeQuestionData.stateShortcode = stateShortcode
                    }} else {
                     props.completeQuestionData.city = ""
                    }
                }).catch(error => {
                    console.log("error while getting city from zipcode:", error);
                })
            }
        }
        props.completeQuestionData['value'] = event.target.value
        if (props?.formType === "v3_applications" && props?.completeQuestionData['dataSet']['formDataKey'] === "sourceApplication") {
            props.completeQuestionData.extraDescription = ''
        }
        props?.validateData()
    }

    let selectedLanguage = ''
    useEffect(() => {
        selectedLanguage = localStorage.getItem('newFormToLanguage') !== null ? localStorage.getItem('newFormToLanguage') : 'es'
        setFormSelectedLanguage(selectedLanguage)
    }, [])

    // Options for sourceApplications question
    const showInputFieldOptions = [
        "Alumni/Past student of the program",
        "Event/Job fair",
        "Online advertisement"
    ]

    return (
        <div className={`single-group-child ${props?.formType} special-component ${props?.btnDisabled ? "disabled" : ''} ${props?.completeQuestionData?.isVisible == false ? 'hide-question' : null} ${props?.className} ${props?.item['isError'] ? 'error-in-question' : ''}`}>
            <strong className={`question-element ${props?.formType} special-component`}><Translate>{props?.item?.question}</Translate>{props?.item?.isRequired ? <span className='required'>*</span> : null}</strong>
            
            {props?.formType === "adny" && props?.completeQuestionData?.addData ? <div>
            <p className='mt-2 fs-5'><Translate>All courses will be held on Zoom. Participants will need a Zoom Account and computer/tablet to receive educational materials.</Translate></p>
            </div> : null}
            
            {(props?.item?.options).map((option, i) => {
                return (<div>
                   {props?.formType === "adny" && props?.completeQuestionData?.addData ? <div className='mt-2'>
                    <p><strong className='text-decoration-underline strongclass mt-2 d-flex'><Translate> <input type="radio" id={(option['line1'])}
                         name={props?.item?.groupName}
                         className={`${props?.completeQuestionData?.isChildren ? 'mt-3' : ''}`}
                         style={{marginRight: '8px'}}
                         defaultValue={props?.completeQuestionData?.isChildren ? `${option['line1']} ${option['line2']} ${option['line3']}` : option} 
                         onChange={(event) => handleChange(event)}
                         checked={props?.completeQuestionData?.isChildren ? props?.completeQuestionData?.value === `${option['line1']} ${option['line2']} ${option['line3']}` : props?.completeQuestionData?.value === option}
                         disabled={props?.btnDisabled}
                         ></input>{props?.item?.courseAdditionalData[i]?.courseTitle}</Translate></strong></p>
                    {props?.item?.courseAdditionalData[i]?.courseTimeline && (props?.item?.courseAdditionalData[i]?.courseTimeline).map(timeline => {
                        return <p className='mb-0 fs-5'><Translate>{timeline}</Translate></p>
                    })}
                    </div> : null
                   }
                    <div className={`option-container ${props?.completeQuestionData?.isChildren ? 'align-start' : ''}`} key={i} style={{marginBottom: props?.formType === "adny" && props?.completeQuestionData?.addData ? '20px' : ''}}>
                        {props?.formType === "adny" && props?.completeQuestionData?.addData ? null : <>
                        <input type="radio" id={(option['line1'])}
                         name={props?.item?.groupName}
                         className={`${props?.completeQuestionData?.isChildren ? 'mt-3' : ''}`}
                         defaultValue={props?.completeQuestionData?.isChildren ? `${option['line1']} ${option['line2']} ${option['line3']}` : option} 
                         onChange={(event) => handleChange(event)}
                         checked={props?.completeQuestionData?.isChildren ? props?.completeQuestionData?.value === `${option['line1']} ${option['line2']} ${option['line3']}` : props?.completeQuestionData?.value === option}
                         disabled={props?.btnDisabled}
                         >
                         </input>
                         {props?.completeQuestionData?.isChildren ? 
                        <div className='mb-2'>
                            <p className='m-0 mb-3 fs-5'><strong><Translate>{option['line1']}</Translate></strong></p>
                            <p className='m-0 mb-3 fs-5'><Translate>{option['line2']}</Translate></p>
                            <p className='m-0 mb-3 fs-5'><Translate>{option['line3']}</Translate></p>
                        </div> : <span className={`${props?.formType} option-label`}>{formSelectedLanguage === "en" ? option : <Translate>{option}</Translate>}</span>
                        }
                        </>
                        }
                    </div>
                </div>
                )
            })}

            {props?.formType === "v3_applications" && props?.completeQuestionData['dataSet']['formDataKey'] === "sourceApplication" && showInputFieldOptions.includes(props.completeQuestionData['value']) ? <div>
                <div className='field'>
                <p className='mt-2 fs-5'><Translate>If you chose {props?.completeQuestionData['value']}, please describe:</Translate></p>
                <input type={props?.item?.inputType} className='textcomponent soulful w-100 form-control'
                    name={props?.item?.groupName}
                    defaultValue={props.completeQuestionData.extraDescription}
                    disabled={props?.btnDisabled}
                    onChange={(event) => {
                        props.completeQuestionData.extraDescription = event.target.value
                    }}
                    onBlur={(event) => {
                        props.completeQuestionData.extraDescription = event.target.value
                    }}
                    style={{
                        borderRadius: '8px',
                        outline: 'none'
                    }}
                    >
                    </input>
                </div>
            </div> : null}
            {props?.item['isError'] ? <p className='error-message-container'><Translate>{props?.item['errorMessage']}</Translate></p> : null}
        </div>
    )
}

export default SimpleRadio