import React from 'react'
import './../../../Footer.css'
import SoulfulLogo from '../../../soulful_logo.png'

const SoulFulFooter = () => {
  return (
    <div className='footer soulful'>
      <img src={SoulfulLogo} alt="" />
      <div className='footercontent soulful'>
        <p></p>
        <h5 className='text-font-sf'>For More information, Contact:</h5>
        <br />
        <br />
        <b className='text-white text-font-sf'>Dwayne R. Norris</b><br />
        <i className='text-white' text-font-sf>Co-Founder & COO <br/>Email: CEA@soulfulsynergy.org</i>
        <p className='copyright soulful text-font-sf'>Copyright  © {new Date().getFullYear()} Soulful Synergy - All Rights are Reserved</p>
      </div>
    </div>
  )
}

export default SoulFulFooter
// For class information, contact:

// Charles Calloway
// Director of Workforce Development
// charles@weact.org